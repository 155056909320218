var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBlackScreen)?_c('div',{staticClass:"black-screen"}):(_vm.showOnlyUserStaticPicture)?_c('div',[_c('div',{staticClass:"banner-container"},[(_vm.tabletSettings.staticPicBackgroundStyle === 'image')?_c('div',{staticClass:"banner-background",style:({
        backgroundImage: `url(${_vm.userStaticPicture})`,
        filter: `blur(${_vm.tabletSettings.staticPicBackgroundImageBlurPercentage}px)`
      })}):_c('div',{staticClass:"banner-background",style:({ backgroundColor: _vm.tabletSettings.staticPicBackgroundColor })}),_c('img',{staticClass:"banner-image",attrs:{"src":_vm.userStaticPicture,"alt":"User static picture"}})])]):_c('div',[(_vm.contentRefreshing)?_c('div',{staticClass:"loading-container"},[_c('b-spinner',{attrs:{"size":"20"}})],1):_vm._e(),(_vm.currentDoctor)?_c('div',{staticClass:"doctor-info-container"},[(_vm.nextDoctorInSchedule)?_c('div',{staticClass:"next-doctor-bar",style:({
        color: _vm.tabletSettings.scheduleUpcomingAppointmentsInfoTextColor,
        backgroundColor: _vm.tabletSettings.scheduleUpcomingAppointmentsInfoBackgroundColor
      })},[_vm._v(" "+_vm._s(_vm.tabletSettings.scheduleUpcomingAppointmentsInfoText)+" "+_vm._s(_vm.nextDoctorInSchedule.title)+" "+_vm._s(_vm.nextDoctorInSchedule.first_name)+" "+_vm._s(_vm.nextDoctorInSchedule.last_name)+" od "+_vm._s(_vm.nextDoctorInSchedule.startTime)+" do "+_vm._s(_vm.nextDoctorInSchedule.endTime)+" ")]):_vm._e(),_c('div',{staticClass:"banner-container"},[(_vm.tabletSettings.scheduleBackgroundStyle === 'image')?_c('div',{staticClass:"banner-background",style:({
          backgroundImage: `url(${_vm.currentDoctor.pictureUrl || _vm.defaultPictureUrl})`,
          filter: `blur(${_vm.tabletSettings.scheduleBackgroundImageBlurPercentage}px)`
        })}):_c('div',{staticClass:"banner-background",style:({ backgroundColor: _vm.tabletSettings.scheduleBackgroundColor })}),(!_vm.currentDoctor.pictureUrl)?_c('div',{staticClass:"doctor-info"},[_c('img',{staticClass:"doctor-photo",attrs:{"src":_vm.defaultPictureUrl,"alt":"Doctor's photo"}}),_c('div',{staticClass:"doctor-details"},[_c('div',{staticClass:"doctor-name"},[_vm._v(" "+_vm._s(_vm.currentDoctor.title)+" "+_vm._s(_vm.currentDoctor.first_name)+" "+_vm._s(_vm.currentDoctor.last_name)+" ")]),_c('div',{staticClass:"doctor-timing"},[_vm._v(" "+_vm._s(_vm.currentDoctor.startTime)+" - "+_vm._s(_vm.currentDoctor.endTime)+" ")])])]):_c('img',{staticClass:"banner-image",attrs:{"src":_vm.currentDoctor.pictureUrl,"alt":"Current doctor image"}})])]):_c('div',[(_vm.nextDoctorInAd)?_c('div',{staticClass:"next-doctor-bar",style:({
        color: _vm.tabletSettings.adUpcomingAppointmentsInfoTextColor,
        backgroundColor: _vm.tabletSettings.adUpcomingAppointmentsInfoBackgroundColor
      })},[_vm._v(" "+_vm._s(_vm.tabletSettings.adUpcomingAppointmentsInfoText)+" "+_vm._s(_vm.nextDoctorInAd.title)+" "+_vm._s(_vm.nextDoctorInAd.first_name)+" "+_vm._s(_vm.nextDoctorInAd.last_name)+" od "+_vm._s(_vm.nextDoctorInAd.startTime)+" do "+_vm._s(_vm.nextDoctorInAd.endTime)+" ")]):_vm._e(),(_vm.currentBanner)?_c('div',{staticClass:"banner-container"},[(_vm.tabletSettings.adBackgroundStyle === 'image')?_c('div',{staticClass:"banner-background",style:({
          backgroundImage: `url(${_vm.currentBanner.image_url})`,
          filter: `blur(${_vm.tabletSettings.adBackgroundImageBlurPercentage}px)`
        })}):_c('div',{staticClass:"banner-background",style:({ backgroundColor: _vm.tabletSettings.adBackgroundColor })}),_c('img',{staticClass:"banner-image",attrs:{"src":_vm.currentBanner.image_url,"alt":"Banner image"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }