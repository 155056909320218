<template>
  <div class="page-container">
    <div class="panel">
      <div class="title mb-2">Zarejestrowane wizyty</div>
      <div class="description">Kliknij w kafelek wizyty, aby przejść do widoku szczegółowego.</div>
      <hr class="mt-0" />
      <div v-if="busy" class="d-flex justify-content-center p-4">
        <b-spinner style="width: 3rem; height: 3rem" type="grow"></b-spinner>
      </div>
      <div v-else>
        <div v-if="visits.length === 0" class="text-center p-3">Brak zarejestrowanych wizyt</div>
        <div
          v-else
          @click="openPatient(item)"
          class="item panel lite-shadow"
          :class="{ corrupted: patient(item.patinet).last_name === '' }"
          v-bind:key="item.registration_id"
          v-for="item in visits"
        >
          <p class="mb-1">
            <b>Pacjent:</b> {{ patient(item.patinet).last_name || '' }}
            {{ patient(item.patinet).first_name || '' }}
            {{ formatAge(extractAge(patient(item.patinet).age)) }}
          </p>
          <p class="mb-1"><b>Typ:</b> {{ item.visit_type_name }}</p>
          <p class="mb-1">
            <b>Sposób rejestracji:</b>
            {{ item.registration_id === null ? 'dołączony dokument' : 'aplikacja online' }}
          </p>
          <p class="mb-1"><b>Klinika:</b> {{ (item && item.localization) || '' }}</p>
          <p class="mb-1">
            <b>Data rejestracji:</b> {{ item.registration_date | moment('YYYY-MM-DD HH:mm') }}
          </p>
          <p class="mb-0">
            <b>Data wizyty:</b>
            {{ `${item.visit_date} ${item.visit_time}` | moment('YYYY-MM-DD HH:mm') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentjs from 'moment'
import { calculateAge } from '@/utils/calculateAge'
import { formatAge } from '../../utils/formatAge'
import { extractAge } from '../../utils/extractAge'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Visits',
  async created() {
    await this.fetch()
  },
  data() {
    return {
      busy: true,
      visits: []
    }
  },
  watch: {
    async date() {
      await this.fetch()
    }
  },
  filters: {
    moment(value, format) {
      if (!value) return ''
      return momentjs(value, format).format('YYYY-MM-DD HH:mm')
    }
  },
  methods: {
    extractAge,
    formatAge,
    calculateAge,
    async openPatient(visit) {
      const vm = this
      vm.$busy(true)
      let { patinet: patient } = visit
      let IDRejestracja = null
      if (visit.registration_id === null) {
        // eslint-disable-next-line prefer-destructuring
        IDRejestracja = visit.IDRejestracja
      }
      try {
        const visitInfo = await vm.fetchVisitInfo(patient.patinet_id, visit.registration_id)
        // eslint-disable-next-line prefer-destructuring
        if (Array.isArray(patient)) patient = patient[0]
        await vm.$db.setItem('patient-data', patient)
        await vm.$db.setItem('currently-viewed-visit', visitInfo)
        vm.$router.push({ name: 'visit-detailed-view', params: { previous: 'visits' } })
      } catch {
        vm.$error('Wystąpił błąd podczas pobierania danych wizyty')
      } finally {
        vm.$busy(false)
      }
    },
    async fetchVisitInfo(patientId, reservationId) {
      const vm = this
      const visits = await vm.$http
        .get(`e-zacma/list-patient-medical-history-v2/${patientId}`)
        .then((r) => r.data)

      return visits?.find((visit) => `${visit.IDRejestracja}` === `${reservationId}`)
    },
    patient(patient) {
      // eslint-disable-next-line prefer-destructuring
      if (Array.isArray(patient)) patient = patient[0]

      if (typeof patient === 'undefined') {
        return {
          first_name: 'brak danych',
          last_name: '',
          social_security_number: ''
        }
      }

      return patient
    },
    format(date) {
      return momentjs(this.date).format('d MMMM YYYY')
    },
    async fetch() {
      const vm = this
      vm.busy = true
      const user = await vm.$db.getItem('user')
      vm.$http
        .post('e-zacma/list-user-registrations-v2', {
          patientId: 0,
          doctorId: user.unison_id
        })
        .then((response) => {
          vm.visits = response.data.sort((a, b) => {
            const aDateTime = `${a.visit_date} ${a.visit_time}`
            const bDateTime = `${b.visit_date} ${b.visit_time}`
            if (aDateTime < bDateTime) {
              return 1
            }
            if (aDateTime > bDateTime) {
              return -1
            }
            return 0
          })
        })
        .catch(() => {})
        .finally(() => {
          vm.busy = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/presets';

.item {
  &.corrupted {
    display: none;
  }

  box-shadow: -4px -4px 12px 2px rgba(0, 0, 0, 0.1), 4px 4px 12px 2px rgba(45, 78, 255, 0.1);

  &.blue {
    border-right: 7px solid $mblue;
  }
}
</style>
