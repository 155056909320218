export function formatAge(age) {
  if (!age || age < 0) {
    return '--'
  }

  const lastDigit = age % 10
  const lastTwoDigits = age % 100

  if (lastDigit >= 2 && lastDigit <= 4 && !(lastTwoDigits >= 12 && lastTwoDigits <= 14)) {
    return `${age} lata`
  }
  return `${age} lat`
}
