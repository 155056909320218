// eslint-disable-next-line import/no-extraneous-dependencies
import { jwtDecode } from 'jwt-decode'

/* TODO: Isolate from Vuex. */

const Auth = {
  install(Vue, options = {}) {
    const prefix = options.prefix || ''

    Vue.prototype.$http.defaults.headers.common['Access-Control-Allow-Headers'] = 'Authorization'

    Auth.restoreSession(Vue)

    /**
     * Setting all the required navigation guards and the injection of the user data.
     */
    Vue.mixin({
      async beforeRouteEnter(to, from, next) {
        const outsideCall = from.name === null
        const authRequired = Auth.checkAccess(to)
        const onlyUnauthorized = Auth.checkIfOnlyUnauthorized(to)
        await Auth.restoreSession(Vue)
        const authorized = await Auth.isAuthorized(Vue)
        const token = await Vue.prototype.$db.getItem('access_token')
        //
        // if (authorized && (await Auth.tokenNeedsToBeRefreshed(Vue.prototype.$db))) {
        //   Auth.refreshToken(Vue)
        // }

        if (navigator.onLine) {
          if (authRequired && authorized) next()
          else if (onlyUnauthorized && authorized) next('/')
          else if (authRequired && !authorized) next({ name: 'sms-login' })
          else if (onlyUnauthorized && !authorized) next()
        } else if (token !== null) {
          next()
        } else {
          next({ name: 'sms-login' })
        }

        if (to.name !== 'home') {
          Vue.prototype.$busy(false)
        }
      }
    })

    Vue.prototype.$http.interceptors.response.use(
      async (response) => {
        try {
          // Sprawdź, czy nowy token został zwrócony
          if (response?.headers?.authorization) {
            const newToken = response.headers.authorization.split(' ')[1]
            await Auth.initSession(Vue.prototype, newToken)
            await Auth.storeToken(newToken, Vue.prototype)
            const decodedToken = jwtDecode(newToken)
            await Vue.prototype.$db.setItem('token_expire_date', decodedToken.exp)
          }
        } catch (error) {
          console.error('Error: ', error)
        }
        return response
      },
      (error) => Promise.reject(error)
    )

    Vue.prototype.$login = function (credentials) {
      const vm = this
      vm.$store.commit('addPageLoaderEvent', 'login')
      return vm.$http
        .post(`${prefix}/login`, credentials)
        .then((response) => {
          const expireDate = Date.now() + response.data.expires_in
          Vue.prototype.$db.setItem('token_expire_date', expireDate)
          Auth.initSession(vm, response.data.access_token)
          Auth.updateAccessToken(vm, response)
        })
        .then(() => {
          localStorage.setItem('lastLoginEmail', credentials.email)
          vm.$router.push({ path: '/' })
          vm.$store.commit('removePageLoaderEvent', 'login')
        })
    }

    Vue.prototype.$user = async function () {
      const user = await this.$http.post(`${prefix}/me`).then((response) => response.data)
      return user
    }

    Vue.prototype.$logout = function () {
      const vm = this
      return vm.$http.post(`${prefix}/logout`).then((response) => {
        Auth.removeAccessToken(Vue).then(() => {
          vm.$router.replace({ path: 'sms-login' })
        })
      })
    }
  },

  /* Checks if any matched route requires identification.
   *  This is vital when it comes to children routes. */
  checkAccess(to) {
    return to.matched.find((route) => route.meta.auth === true) !== undefined
  },

  removeAccessToken(Vue) {
    localStorage.removeItem('access_token')
    return Vue.prototype.$db.removeItem('access_token')
  },

  updateAccessToken(vm, response) {
    if (response.data.hasOwnProperty('access_token')) {
      const token = response.data.access_token
      Auth.storeToken(token, vm)
    }
  },

  async storeToken(token, vm) {
    vm.$db.setItem('access_token', token).then(() => {
      Auth.initSession(vm, token)
    })
  },

  async initSession(vm, token) {
    vm.$http.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  async restoreSession(Vue) {
    await Vue.prototype.$db.getItem('access_token').then((token) => {
      Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`
    })
  },

  async isAuthorized(Vue) {
    return Vue.prototype.$http
      .post('/auth/check')
      .then((res) => true)
      .catch((err) => false)
  },

  checkIfOnlyUnauthorized(to) {
    return to.meta.onlyUnauthorized !== undefined
  },

  async tokenNeedsToBeRefreshed($db) {
    return $db.getItem('token_expire_date').then((date) => date <= Date.now() - 24 * 60 * 60 * 1000)
  },
  refreshToken(Vue) {
    Vue.prototype.$http.post('/auth/refresh').then((response) => {
      const { data } = response
      const expireDate = Date.now() + data.expires_in
      const p1 = Vue.prototype.$db.setItem('token_expire_date', expireDate)
      const p2 = Vue.prototype.$db.setItem('access_token', data.access_token)
      Promise.all([p1, p2]).then(() => {
        Auth.restoreSession(Vue)
      })
    })
  }
}

export default Auth.install
