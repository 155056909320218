<template>
  <div class="page-container">
    <div class="panel">
      <div class="title">Lista pacjentów</div>
      <div class="description">
        Lista wszystkich pacjentów zarejestrowanych za pomocą twojej aplikacji
      </div>
      <b-form-group
        id="input-group-1"
        label=""
        label-for="input-1"
        description="Możliwość wyszukania pacjenta po: imieniu, nazwisku, numerze telefonu oraz numerze PESEL"
      >
        <b-form-input
          id="input-1"
          v-model="filter"
          type="search"
          placeholder="Wyszukaj pacjenta..."
        ></b-form-input>
      </b-form-group>
      <!-- TODO: W zależności od długości line-height -->
      <b-table
        :items="pagination.data"
        :fields="patients.fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy.sync="isBusy"
        responsive
        show-empty
      >
        <div slot="table-busy" class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong> Ładowanie...</strong>
        </div>
        <template v-slot:cell(last_name)="row">
          {{ row.item.last_name }} {{ row.item.first_name }}
          {{ formatAge(calculateAge(row.item.social_security_number)) }}
        </template>
        <template v-slot:cell(actions)="row">
          <b-button
            size="sm"
            @click="openPatientPage(row.item)"
            class="mr-1 button pink no-shadow height-auto user-button"
          >
            <font-awesome-icon icon="user" />
          </b-button>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1">
          <b-pagination
            :value="pagination.current_page"
            :total-rows="pagination.total"
            :per-page="pagination.per_page"
            @change="changePage"
          ></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import mixin from '../../mixin'
import { API } from '../../api/api_endpoints'
import { formatAge } from '../../utils/formatAge'
import { calculateAge } from '../../utils/calculateAge'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'List',
  mixins: [mixin],
  mounted() {
    const vm = this
    vm.maskEmpty()
    vm.downloadPatients(1)
  },
  data() {
    return {
      result: [],
      pagination: {
        data: [],
        current_page: 1,
        per_page: 10,
        total: 10
      },
      filter: '',
      sortBy: 'last_name',
      sortDesc: null,
      patients: {
        fields: [
          { key: 'last_name', label: 'Imię i nazwisko', sortable: true },
          // { key: 'name', label: 'Imię', sortable: true },
          { key: 'actions', label: '' }
        ]
      },
      isBusy: true
    }
  },
  watch: {
    filter: _.debounce(function () {
      this.changePage(1)
    }, 300),
    sortDesc() {
      this.changePage(1)
    },
    sortBy() {
      this.changePage(1)
    }
  },
  methods: {
    calculateAge,
    formatAge,
    changePage(page) {
      const vm = this
      vm.pagination.current_page = page
      const { data, total } = vm.getDataForPage(page)
      vm.pagination.data = data
      vm.pagination.total = total
    },
    useQueryFilters(page) {
      const vm = this
      const query = vm.setQuery(page)
    },
    getDataForPage(page) {
      const vm = this
      const start = (page - 1) * vm.pagination.per_page
      const end = page * vm.pagination.per_page
      let filteredData = vm.result

      if (vm.filter) {
        filteredData = filteredData.filter(
          (item) => item.first_name.toLowerCase().includes(vm.filter.toLowerCase())
            || item.last_name.toLowerCase().includes(vm.filter.toLowerCase())
            || item.phone.toLowerCase().includes(vm.filter.toLowerCase())
            || item.social_security_number.toLowerCase().includes(vm.filter.toLowerCase())
        )
      }

      if (vm.sortBy) {
        filteredData = filteredData.sort((a, b) => {
          const propA = a[vm.sortBy]
          const propB = b[vm.sortBy]
          if (propA < propB) {
            return vm.sortDesc ? 1 : -1
          }
          if (propA > propB) {
            return vm.sortDesc ? -1 : 1
          }
          return 0
        })
      }

      return { data: filteredData.slice(start, end), total: filteredData.length }
    },
    async downloadPatients(page) {
      const vm = this
      vm.isBusy = true

      const query = vm.setQuery(page)
      vm.$http
        .post(`${API.PATIENTS_LIST_2}`)
        .then((response) => {
          vm.result = response.data
          const { data, total } = vm.getDataForPage(1)
          vm.pagination.data = data
          vm.pagination.total = total
          vm.isBusy = false
        })
        .catch(() => {
          vm.isBusy = false
        })
    },
    maskEmpty() {
      const vm = this
      const element = document.querySelector('table tbody .b-table-empty-row .text-center')
      if (element) {
        element.textContent = 'Na twoim koncie nie ma jeszcze zarejestrowanych pacjentów'
      }
      vm.waitFor(
        'table tbody',
        '.b-table-empty-row',
        (el, next) => {
          let text = ''
          const innerText = el.querySelector('.text-center').textContent
          if (innerText === 'There are no records matching your request') {
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(parseInt(vm.patients.filter, 10))) {
              text = 'Brak wyników wyszukiwania'
            } else {
              text = `Nie wyszukano żadnego pacjenta o numerze PESEL ${vm.patients.filter}`
              text += vm.patients.filter.length === 11 ? '' : '...'
            }
            next.ignore()
          } else {
            text = 'Na twoim koncie nie ma jeszcze zarejestrowanych pacjentów'
            next.ignore()
          }
          el.querySelector('.text-center').textContent = text
        },
        false
      )
    },
    /**
     * Method that waits until requested node appears in the parent element and then calls callback
     * function and passes that element as a callback parameter
     * @param parent String Any proper querySelector query
     * @param requested String Class of the element
     * @param callback Function Callback function
     * @param onetime Bool
     */
    waitFor(parent, requested, callback, onetime = true) {
      const parentElement = document.querySelector(parent)
      const config = { attributes: false, childList: true, subtree: true }
      const Next = class {
        constructor() {
          this.tick = {
            ignore: false
          }
        }

        ignore() {
          this.tick.ignore = true
        }

        reset() {
          this.tick.ignore = false
        }
      }
      const next = new Next()
      const observer = new MutationObserver((mutations, _observer) => {
        const element = parentElement.querySelector(requested)
        if (element && !next.tick.ignore) {
          callback(element, next)
          if (onetime) {
            _observer.disconnect()
          }
        } else {
          next.reset()
        }
      })
      observer.observe(parentElement, config)
    },
    async openPatientPage(patient) {
      const vm = this
      await vm.$db.setItem('patient-data', patient)
      vm.$router.push({ name: 'patient' })
    },
    setQuery(page) {
      const vm = this
      const query = []

      query.page = page
      if (vm.filter) {
        query.search = vm.filter
      }
      if (vm.sortBy) {
        query.sortBy = vm.sortBy
        query.orderBy = 'asc'
        if (vm.sortDesc) {
          query.orderBy = 'desc'
        }
      }

      return query
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/presets';

.table tr td:last-child {
  text-align: right;
  width: 60px;
}

.table td {
  font-size: 100% !important;
  line-height: 50px;
}

.user-button {
  width: 60px;
}
</style>
