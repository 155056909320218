const OfflineIndicator = {
  install(Vue, options = {}) {
    setInterval(() => {
      navigator.onLine ? OfflineIndicator.online(Vue) : OfflineIndicator.offline(Vue)
    }, 5000)
    window.addEventListener('offline', (e) => {
      OfflineIndicator.offline(Vue)
    })
    window.addEventListener('online', (e) => {
      OfflineIndicator.online(Vue)
    })
    try {
      let currentSpeed = navigator.connection.downlink
      if (currentSpeed < 1.5) OfflineIndicator.slowConnection(Vue)
      navigator.connection.addEventListener('change', () => {
        currentSpeed = navigator.connection.downlink
        if (currentSpeed < 1.5) OfflineIndicator.slowConnection(Vue)
        else OfflineIndicator.removeSlowConnectionToast(Vue)
      })
    } catch (e) {
      console.error('Navigator network speed not supported')
    }
  },
  slowConnection(Vue) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.account_type === 'clinic_office') return

    Vue.prototype.$toast.warning({
      title: 'Wykryto wolne połączenie z Internetem',
      message: 'Aplikacja może nie działać prawidłowo',
      displayMode: 2,
      zindex: 9999,
      close: true,
      closeOnEscape: true,
      closeOnClick: true,
      drag: true,
      timeout: false,
      class: 'slow-internet-connection-toast'
    })
  },
  offline(Vue) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user.account_type === 'clinic_office') return

    if (document.getElementById('offline-mask') !== null) return
    const body = document.getElementsByTagName('body')[0]
    const div = document.createElement('div')
    div.style.width = '100vw'
    div.style.height = '100vh'
    div.style.position = 'fixed'
    div.style.top = '0'
    div.style.left = '0'
    div.style.background = 'rgba(221,221,221,0.6)'
    div.style.zIndex = '9998'
    div.id = 'offline-mask'
    body.appendChild(div)
    Vue.prototype.$toast.error({
      title: 'Twój telefon nie ma połączenia z Internetem',
      message: 'W celu poprawnego działania aplikacji podłącz urządenie do sieci',
      displayMode: 2,
      zindex: 9999,
      close: false,
      closeOnEscape: false,
      closeOnClick: false,
      drag: false,
      timeout: false,
      class: 'no-internet-connection-toast'
    })
  },
  removeSlowConnectionToast(Vue) {
    const iziToastWrapper = document.getElementsByClassName('iziToast-wrapper')[0]
    const toasts = [...document.getElementsByClassName('iziToast-capsule')]
    // eslint-disable-next-line no-restricted-syntax
    for (const node of toasts) {
      iziToastWrapper.removeChild(node)
    }
  },
  online(Vue) {
    const body = document.getElementsByTagName('body')[0]
    const div = document.getElementById('offline-mask')
    const iziToastWrapper = document.getElementsByClassName('iziToast-wrapper')[0]
    const toasts = [...document.getElementsByClassName('iziToast-capsule')]
    if (div === null) return
    body.removeChild(div)
    // eslint-disable-next-line no-restricted-syntax
    for (const node of toasts) {
      iziToastWrapper.removeChild(node)
    }
    Vue.prototype.$toast.success({
      title: 'Połączenie z Internetem przywrócone',
      displayMode: 2,
      zindex: 9999,
      close: false,
      closeOnEscape: false,
      closeOnClick: false,
      drag: false,
      timeout: 1000
    })
  }
}

export default OfflineIndicator.install
