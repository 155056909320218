<template>
  <nav
    class="main-navbar fixed"
    v-if="$route.name !== 'qr-auto-login' && $route.name !== 'sms-login'"
    :class="{ fixed: $route.name === 'account' }"
  >
    <div class="navbar-container">
      <div v-if="$route.name === 'home'" class="col-1">
        <div v-show="!menuOpened" class="toggler" @click="toggle">
          <font-awesome-icon icon="bars" />
        </div>
        <div v-show="menuOpened" class="toggler" @click="toggle">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <div
        v-if="$route.name !== 'home'"
        class="previous"
        :class="{ 'slideOutLeft animated': animatePrevBtnClass }"
      >
        <a @click="previous"><font-awesome-icon :icon="['fas', 'arrow-left']" /> </a>
        <b-nav-text v-show="navBarHeaderTitle !== ''" class="visit-type">{{
          navBarHeaderTitle
        }}</b-nav-text>
        <a @click="home" class="home-button"><font-awesome-icon :icon="['fas', 'home']" /> </a>
      </div>
    </div>
    <div class="opened-menu-items" :class="{ opened: menuOpened }">
      <div class="username" @click="account">
        <font-awesome-icon class="mr-2" :icon="['fas', 'user']" />{{ username }}
      </div>
      <div v-if="isClinicOffice" @click="toggleLock">
        <font-awesome-icon class="mr-2" :icon="['fas', 'lock']" /> Zablokuj ekran
      </div>
      <div @click="logout" class="danger">Wyloguj</div>
    </div>
  </nav>
  <nav v-else></nav>
</template>

<script>
export default {
  name: 'Navbar',
  props: ['isClinicOffice'],
  async mounted() {
    await this.hooks()
    await this.setUserName()
  },
  data() {
    return {
      menuOpened: false,
      animatePrevBtnClass: false,
      navBarHeaderTitle: '',
      previousRoute: 'home',
      username: '',
      account_type: ''
    }
  },
  watch: {
    async $route(to, from) {
      await this.hooks(to, from)
    }
  },
  computed: {
    isProd() {
      return process.env.NODE_ENV === 'production'
    }
  },
  methods: {
    toggleLock() {
      this.$emit('lock')
      this.$toast.success({ title: 'Ekran Zablokowany!', timeout: 500 })
      this.toggle()
    },
    async setUserName() {
      const vm = this
      const account = await vm.$db.getItem('account')
      if (account) {
        vm.username = `${account.name} ${account.last_name}`
      }
    },
    getAccountType(accountType) {
      switch (accountType) {
        case 'doctor':
          return 'Personel medyczny kliniki z funkcją konsultanta'
        case 'specialist':
          return 'Personel medyczny kliniki'
        case 'consultant':
          return 'Kontultant'
        case 'clinic_staff':
          return 'Personel kliniki'
        case 'clinic_staff_with_consultant_feature':
          return 'Personel kliniki z funkcją konsultanta'
        default:
          return 'Nieznany'
      }
    },
    async account() {
      this.$busy(true)
      await this.$router.push({ name: 'account' })
      this.$busy(false)
    },
    async home() {
      this.$busy(true)
      await this.hooks()
      await this.$router.push({ path: '/' })
      this.$busy(false)
    },
    async previous() {
      this.$busy(true)
      await this.hooks()
      await this.$router.push({ name: this.previousRoute })
      this.$busy(false)
    },
    async hooks(to = {}, from = {}) {
      const vm = this
      vm.menuOpened = false
      let previousRoute = await vm.$db.getItem('previous_route')

      if (to.name === previousRoute) {
        await vm.$db.removeItem('previous_route')
        previousRoute = null
      }

      if (to.name === 'visit-type') {
        await vm.clearNavBarHeaderTitle()
      }

      // console.log(previousRoute, vm.$route.params.previous, vm.$route.meta.previous)
      if (to.name === 'calendar') {
        const visitTypeName = await vm.$db.getItem('visit_type')
        await vm.setNavbarHeaderTitle(visitTypeName)
        await vm.releaseRegistrationSlot()
      }

      if (to.name === 'visit-type') {
        await vm.$db.setItem('calendar_month', undefined)
        await vm.$db.setItem('calendar_year', undefined)
        await vm.$db.removeItem('calendar_variant')
        await vm.$db.removeItem('is_nfz_available')
      }

      if (to.name === 'home') {
        await vm.clearNavBarHeaderTitle()
        await vm.releaseRegistrationSlot()
        await vm.clearSearchResults()
        await vm.clearSelectedLocalization()
      }

      if (to.name === 'doctor-visits') {
        await vm.setNavbarHeaderTitle('Moje wizyty')
      }

      if (to.name === 'doctor-patient-search') {
        await vm.setNavbarHeaderTitle('Wyszukaj pacjenta')
      }

      if (previousRoute !== null) {
        vm.previousRoute = previousRoute
        await vm.$db.removeItem('previous_route')
      } else if (vm.$route.params.previous === undefined) {
        vm.previousRoute = vm.$route.meta.previous
      } else {
        vm.previousRoute = vm.$route.params.previous
        await vm.$db.setItem('previous_route', vm.previousRoute)
      }
    },
    async clearSelectedLocalization() {
      await this.$db.removeItem('previous_selected_localization')
    },
    async clearSearchResults() {
      await this.$db.removeItem('search_results')
      await this.$db.removeItem('search_text')
    },
    async setNavbarHeaderTitle(value) {
      const vm = this
      // eslint-disable-next-line no-multi-assign
      vm.navBarHeaderTitle = vm.navBarHeaderTitle = value
    },
    async clearNavBarHeaderTitle() {
      const vm = this
      vm.navBarHeaderTitle = ''
    },
    async releaseRegistrationSlot() {
      const vm = this
      const temporaryVisit = await vm.$db.getItem('temporary_visit_info')
      if (temporaryVisit) {
        await this.$store.commit('calendarNeedToBeRerender', {
          updateNeeded: true,
          refreshNow: false
        })
        const input = {
          api_key: temporaryVisit.api_key,
          slot_reservation_id: temporaryVisit.id
        }
        await this.$http.post('e-zacma/release-temporary-registration', input)
        await vm.$db.removeItem('temporary_visit_info')
        await this.$store.commit('calendarNeedToBeRerender', {
          updateNeeded: true,
          refreshNow: true
        })
      } else {
        await this.$store.commit('calendarNeedToBeRerender', {
          updateNeeded: false,
          refreshNow: false
        })
      }
    },
    async toggle() {
      const vm = this
      await vm.setUserName()
      vm.menuOpened = !vm.menuOpened
      vm.dispatchIconChangeEvent()
    },
    dispatchIconChangeEvent() {
      const toggleEvent = new Event('toggle-menu')
      document.dispatchEvent(toggleEvent)
    },
    animatePrevBtn() {
      const vm = this
      vm.animatePrevBtnClass = true
      setTimeout(() => {
        vm.animatePrevBtnClass = false
      }, 500)
    },
    logout() {
      this.logoutConfirmModal()
    },
    logoutConfirmModal() {
      const vm = this
      vm.$modalConfirm(
        'Czy na pewno chcesz się wylogować?',
        () => {
          vm.$logEvent('logout', {})
          vm.$logout()
        },
        () => {}
      )
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/presets';

nav.main-navbar {
  height: 55px;
  box-sizing: border-box;
  @include theme-gradient();

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    // padding-top: var(--safe-area-inset-top);
    height: max(55px, calc(40px + var(--safe-area-inset-top)));
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    // padding-top: var(--safe-area-inset-top);
    height: max(55px, calc(40px + var(--safe-area-inset-top)));
  }

  &.fixed {
    position: fixed;
    top: 0;
    z-index: 9991;
    width: 100%;
  }

  .navbar-container {
    height: inherit;
    box-sizing: border-box;
    justify-content: space-between;

    // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
    @supports (padding-top: constant(safe-area-inset-top)) {
      --safe-area-inset-top: constant(safe-area-inset-top);
      padding-top: calc(var(--safe-area-inset-top) - 15px);
    }

    // Browsers which fully support CSS Environment variables (iOS 11.2+).
    @supports (padding-top: env(safe-area-inset-top)) {
      --safe-area-inset-top: env(safe-area-inset-top);
      padding-top: calc(var(--safe-area-inset-top) - 15px);
    }

    .visit-type {
      flex: 1;
      left: 15%;
      text-align: center;
      margin-top: 5px;
      padding-top: inherit;
      font-size: 16px;
    }

    .previous,
    .toggler {
      // height: inherit;
      padding-left: 20px;
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 30px;

      > * {
        color: $white;
      }
    }

    .previous {
      display: flex;
      justify-content: space-between;
      width: 100%;
      gap: 20px;
    }

    .home-button {
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .opened-menu-items {
    max-height: 0;
    overflow-y: hidden;
    transition: max-height 0.5s ease-out;
    // border: 2px solid $white;
    // margin-top: -4px;
    height: auto;
    position: relative;
    z-index: 9997;
    @include theme-gradient();
    // padding-bottom: 20px;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    & > .username {
      width: 100%;
      overflow: auto;
      overflow-wrap: break-word;
      padding: 0 10px;
      cursor: default;
    }

    &.opened {
      max-height: 180px;
      // margin-top: 0;
      @include box-shadow();
    }

    > div:first-child {
      cursor: pointer;
    }

    > div {
      width: 100%;
      margin: 0 auto;
      height: 60px;
      @include theme-gradient();
      text-align: center;
      color: $white;
      line-height: 60px;
      font-size: 18px;
      font-weight: 900;
      cursor: pointer;

      &.danger {
        background-image: linear-gradient(
          to right,
          #f78ca0 0%,
          #f9748f 19%,
          #fd868c 60%,
          #fe9a8b 100%
        );
      }
    }
  }
}

/* Menu icon styles */

.menu-icon-wrapper {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 34px;
  margin: 2em;
  pointer-events: none;
  transition: 0.1s;
}

.menu-icon-wrapper.scaled {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}

.menu-icon-wrapper svg {
  position: absolute;
  top: -40px;
  left: -43px;
  -webkit-transform: scale(0.1);
  -ms-transform: scale(0.1);
  transform: scale(0.1);
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.mod-touchevents {
  .menu-icon-wrapper svg {
    /* iPhoneX support */
    @supports (height: max(0, env(safe-area-inset-top))) {
      top: -33px;
    }
  }
}

.menu-icon-wrapper svg path {
  stroke: #fff;
  stroke-width: 45px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: transparent;
}

.menu-icon-wrapper .menu-icon-trigger {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  pointer-events: auto;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.menu-icon-wrapper .menu-icon-trigger:hover,
.menu-icon-wrapper .menu-icon-trigger:focus {
  outline: none;
}
</style>
