<template>
  <div class="page-container">
    <patient-data-view v-if="patientData" :patient-data="patientData" />
    <medical-history :medicalHistory="medicalHistory" :busy="busy"></medical-history>
  </div>
</template>

<script>
import PatientDataView, { PatientDataType } from '@/components/PatientDataView.vue'
import medicalHistory from '../../components/MedicalHistory.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Single.vue',
  async mounted() {
    const vm = this
    const patient = await vm.$db.getItem('patient-data')
    vm.patient_id = patient.patient_id
    vm.patientData = new PatientDataType({
      first_name: patient.first_name,
      last_name: patient.last_name,
      social_security_number: patient.social_security_number,
      phoneNumber: patient.phone
    })
    try {
      await vm.fetchPatientMedicalHistory()
    } catch {
      vm.$error('Nie udało się pobrać historii choroby pacjenta')
    } finally {
      vm.busy = false
    }
  },
  data() {
    return {
      patient_id: null,
      patientData: null,
      medicalHistory: [],
      busy: true
    }
  },
  computed: {},
  methods: {
    async fetchPatientMedicalHistory() {
      const vm = this
      vm.medicalHistory = await vm.$http
        .get(`e-zacma/list-patient-medical-history-v2/${vm.patient_id}`)
        .then((r) => r.data)
      vm.deleteInvalidDuplicates()
    },
    deleteInvalidDuplicates() {
      const vm = this
      const visits = {}
      const newMedicalHistory = []

      // eslint-disable-next-line no-restricted-syntax
      for (const visit of vm.medicalHistory) {
        if (visit.status !== -100) {
          visits[visit.IDRejestracja] = visit
        }
        // if (visit.reservation_id === '') {
        //   visits[visit.IDRejestracja] = visit
        // } else if (typeof visits[visit.reservation_id] === 'undefined') {
        //   visits[visit.reservation_id] = visit
        // } else if (visit.status > visits[visit.reservation_id].status) {
        //   visits[visit.reservation_id] = visit
        // }
      }

      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const index in visits) {
        newMedicalHistory.push(visits[index])
      }

      vm.medicalHistory = newMedicalHistory
    }
  },
  components: {
    medicalHistory,
    PatientDataView
  }
}
</script>

<style scoped lang="scss"></style>
