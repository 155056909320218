export function extractAge(str) {
  if (str === '-') {
    return -1
  }

  const ageMatch = str.match(/\d+/)
  if (ageMatch) {
    return parseInt(ageMatch[0], 10)
  }
  return -1
}
