export const API = {
  // Patients list for patients view - V2 - from UNISON!
  PATIENTS_LIST_2: 'e-zacma/doctor/consultant-patients',

  // Upload consent image
  UPLOAD_CONSENT: 'e-zacma/upload-consent',

  // Upload new referral document
  UPLOAD_NEW_REFERRAL: 'e-zacma/medical-document/upload-referral-new',

  // Upload medical document for visit view
  UPLOAD_MEDICAL_DOCUMENT: 'e-zacma/medical-document/upload',
  UPLOAD_MEDICAL_DOCUMENT_AS_SPECIALIST: 'e-zacma/medical-document/specialist-upload'
}
