<template>
  <div>
    <UnisonSpinner v-if="busy" with-container="true" />
    <ImagePreview
      :file-data="file"
      :ref="imagePreviewRef"
      @imageFileChanged="imageChanged"
      @processFile="sendFile"
    >
      Wyślij zdjęcie dokumentu
    </ImagePreview>

    <div class="home page-container" v-if="!file">
      <div v-if="isConsultant" class="panel">
        <div class="title">Rejestracja pacjenta</div>
        <div class="description">Zarejestruj pacjenta na wizytę.</div>
        <div class="row">
          <div class="col-12">
            <router-link :to="{ name: 'visit-type' }">
              <button class="button pink btn">Wyszukiwarka terminów</button>
            </router-link>
          </div>
          <div class="col-12">
            <router-link :to="{ name: 'visits' }">
              <button class="button pink btn mt-3">Zarejestrowane wizyty</button>
            </router-link>
          </div>
          <div class="col-12">
            <button class="mt-3 button pink btn" @click="addDocumentImage">
              Wyślij zdjęcie dokumentu
            </button>
          </div>
        </div>
      </div>

      <div v-if="isConsultant" class="panel">
        <div class="title">Zarejestrowani przez aplikację</div>
        <div class="description">
          Lista wszystkich pacjentów zarejestrowanych za pomocą Twojej aplikacji.
        </div>
        <router-link v-show="sumOfVisitsToReview" :to="{ name: 'visits-to-review' }">
          <button class="button btn">
            Zlecenia do konsultacji
            <b-badge variant="danger" class="ml-2">{{ sumOfVisitsToReview }}</b-badge>
          </button>
        </router-link>
        <router-link :to="{ name: 'patients' }">
          <button class="button pink btn mt-3">Zobacz listę</button>
        </router-link>
      </div>
      <!--      <div v-if="isOrganizationAdmin" class="panel">-->
      <!--        <div class="title">Pacjenci</div>-->
      <!--        <div class="description">-->
      <!--          Zobacz listę swoich pacjentów oraz na jakim etapie leczenia aktualnie się znajdują.-->
      <!--        </div>-->
      <!--        <router-link :to="{ name: 'patients' }">-->
      <!--          <button class="button pink btn">Zobacz listę pacjentów</button>-->
      <!--        </router-link>-->
      <!--      </div>-->

      <div v-if="isClinicStaffConsultant" class="panel">
        <div class="title">Rejestracja pacjenta</div>
        <div class="description">Zarejestruj pacjenta na wizytę.</div>
        <div class="row">
          <div class="col-12">
            <router-link :to="{ name: 'visit-type' }">
              <button class="button pink btn">Wyszukiwarka terminów</button>
            </router-link>
          </div>
          <div class="col-12">
            <label class="mt-3 button pink btn" @click="addDocumentImage">
              Wyślij zdjęcie dokumentu
            </label>
          </div>
        </div>
      </div>

      <div v-if="isClinicStaff" class="panel">
        <div class="title">Wszystkie wizyty</div>
        <div class="description">Zobacz listę wizyt</div>
        <button class="button pink btn" @click="clinicDoctorsClicked">Zobacz listę</button>
        <router-link v-if="isPatientSearchFeatureEnable" :to="{ name: 'doctor-patient-search' }">
          <button class="button pink btn mt-3">Wyszukaj pacjenta</button>
        </router-link>
      </div>

      <div v-if="isSpecialistOrDoctor" class="panel">
        <div class="title">Wszystkie wizyty</div>
        <div class="description">Zobacz listę wizyt</div>
        <button class="button pink btn" @click="myPannedVisitClicked">Zobacz listę</button>
        <router-link v-if="isPatientSearchFeatureEnable" :to="{ name: 'doctor-patient-search' }">
          <button class="button pink btn mt-3">Wyszukaj pacjenta</button>
        </router-link>
      </div>
    </div>

    <clinic-office v-if="isClinicOfficeUser" />
  </div>
</template>

<script>
import ClinicOffice from '@/views/ClinicOffice/ClinicOffice.vue'
import UnisonSpinner from '../components/Spinner.vue'
import ImagePreview from '../components/ImagePreview.vue'
import { API } from '../api/api_endpoints'

export default {
  components: { ClinicOffice, ImagePreview, UnisonSpinner },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'home',
  async created() {
    const vm = this
    vm.busy = true
    vm.clearRegisterCache()
    await vm.$parent.updateUserInfo(true)
    await vm.fetchSumOfVisitsToReview()
    await vm.$db.getItem('account').then((account) => {
      vm.isBeneficiary = !!account.is_beneficiary
      vm.isPatientSearchFeatureEnable = !!account.patient_search_feature
    })
    await vm.$db.getItem('user').then((user) => {
      vm.accountType = user.account_type
    })
    vm.busy = false
    vm.$busy(false)
  },
  mounted() {
    const initMenuOpener = new Event('init-menu-opener')
    document.dispatchEvent(initMenuOpener)
  },
  data() {
    return {
      file: null,
      imagePreviewRef: 'homeImagePreviewRef',
      busy: false,
      sumOfVisitsToReview: null,
      isBeneficiary: false,
      isPatientSearchFeatureEnable: false,
      accountType: ''
    }
  },
  computed: {
    isClinicStaff() {
      return (
        this.accountType === 'clinic_staff'
        || this.accountType === 'clinic_staff_with_consultant_feature'
      )
    },
    isClinicOfficeUser() {
      return this.accountType === 'clinic_office'
    },
    isClinicStaffConsultant() {
      return this.accountType === 'clinic_staff_with_consultant_feature'
    },
    isConsultant() {
      return this.accountType === 'consultant' || this.accountType === 'doctor'
    },
    isOrganizationAdmin() {
      return this.accountType === 'organization_admin' || this.accountType === 'admin'
    },
    isSpecialistOrDoctor() {
      return this.accountType === 'specialist' || this.accountType === 'doctor'
    }
  },
  methods: {
    clinicDoctorsClicked() {
      this.$db.removeItem('clinic_calendar_selected_day')
      this.$router.push({ name: 'clinic-doctors' })
    },
    myPannedVisitClicked() {
      this.$db.removeItem('calendar_selected_day')
      this.$router.push({ name: 'doctor-visits' })
    },
    imageChanged(newImageData) {
      this.file = newImageData
    },
    clearRegisterCache() {
      const vm = this
      vm.$db.removeItem('consent-picture')
      vm.$db.removeItem('visit_type')
      vm.$db.removeItem('calendar_request_settings')
      vm.$db.removeItem('consent_type')
      vm.$db.removeItem('calendar_year')
      vm.$db.removeItem('calendar_month')
      vm.$db.removeItem('calendar_variant')
      vm.$db.removeItem('is_nfz_available')
    },
    async fetchSumOfVisitsToReview() {
      const vm = this
      await vm.$http.post('e-zacma/surgery/count-to-review').then((response) => {
        vm.sumOfVisitsToReview = response.data
      })
    },
    addDocumentImage() {
      this.$logEvent('add_picture_of_document', {})
      this.$refs[this.imagePreviewRef].triggerInput()
    },
    sendFile(blob) {
      const vm = this
      vm.$busy(true)

      const formData = new FormData()
      formData.append('file', blob)

      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      this.$http
        .post(API.UPLOAD_NEW_REFERRAL, formData, config)
        .then(() => {
          vm.$success('Dokument został wysłany')
          vm.imageChanged(null)
        })
        .catch(() => vm.$error('Błąd wysyłki dokumentu'))
        .finally(() => vm.$busy(false))
      vm.$logEvent('send_picture_of_document', {})
    }
  }
}
</script>

<style scoped lang="scss">
.page-btn {
  height: calc((100vh - 92px - 48px) / 3);
  font-size: 3em;
}

#file-input {
  position: absolute;
  z-index: 0;
}
</style>
