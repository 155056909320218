<template>
  <div class="item panel lite-shadow" @click="openDetailedView(doctorInfo)">
    <div class="visit-type">
      <b>{{ doctorInfo.title || '' }} {{ doctorInfo.first_name }} {{ doctorInfo.last_name }}</b>
    </div>
    <div>{{ renderSchedule() }}</div>
  </div>
</template>
<script>
export default {
  name: 'DoctorItem',
  props: ['doctorInfo', 'selected'],
  data() {
    return {
      isBusy: false
    }
  },
  methods: {
    async openDetailedView(doctor) {
      this.$busy(true)
      await this.$emit('doctor-selected', doctor)
    },
    renderSchedule() {
      const vm = this
      const { schedule } = vm.doctorInfo
      return schedule.join(', ')
    }
  }
}
</script>
<style></style>
