<template>
  <div class="page-container">
    <div v-if="!selectedLocalization">
      <div class="panel">
        <div class="title">Wybierz lokalizację</div>
        <button
          @click="chooseLocalization(item)"
          class="button pink btn mt-3"
          v-bind:key="item.apiKey"
          v-for="item in localizations"
        >
          {{ item.name }} {{ item.address }} {{ item.postalCode }} {{ item.city }}
        </button>
      </div>
    </div>
    <div v-else>
      <div class="panel flex-panel">
        <div>
          <div class="title">
            {{ localizations.length > 1 ? 'Wybrana lokalizacja' : 'Lokalizacja' }}
          </div>
          <div>
            {{ selectedLocalization.name }} {{ selectedLocalization.address }}
            {{ selectedLocalization.postalCode }} {{ selectedLocalization.city }}
          </div>
        </div>
        <div v-if="localizations.length > 1">
          <button class="button pink btn mt-3" @click="clearSelectedLocalization()">Zmień</button>
        </div>
      </div>
      <div class="calendar">
        <calendar class="calendar-fixed-container" @dateChanged="update" @purge="purge" />
      </div>
      <div v-if="isBusy" class="p-2 col-12 spinner-container">
        <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
      </div>
      <div v-else-if="doctorsList.length === 0" class="p-2 col-12">
        Brak personelu medycznego w danym dniu.
      </div>
      <div v-else class="day-picker-container" :class="{ 'animated fadeIn': !isBusy }">
        <DoctorItem
          :doctor-info="doctor"
          v-bind:key="doctor.id"
          v-for="doctor in doctorsList"
          @doctor-selected="viewDoctorVisits"
        />
      </div>
    </div>
  </div>
</template>
<script>
import DoctorItem from './components/DoctorItem.vue'
import Calendar from './components/Calendar.vue'

export default {
  name: 'ClinicDoctors',
  components: { DoctorItem, Calendar },
  data() {
    return {
      isBusy: true,
      selectedLocalization: undefined,
      selectedDate: {},
      localizations: [],
      allowed: [],
      scope: {},
      doctorsList: []
    }
  },
  async mounted() {
    const vm = this

    vm.$busy(true)

    vm.localizations = await vm.$db.getItem('locations')
    vm.$logEvent('clinic_visits', { localizations: vm.localizations })

    if (vm.localizations.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      vm.selectedLocalization = vm.localizations[0]
    }

    await vm.restoreSelectedLocalization()
    vm.$busy(false)
  },
  methods: {
    async restoreSelectedLocalization() {
      const vm = this
      const selectedLocalization = await vm.$db.getItem('previous_selected_localization')
      if (selectedLocalization) {
        const localizations = await vm.$db.getItem('locations')

        const foundLocalization = localizations.find(
          (el) => el.apiKey === selectedLocalization.apiKey
        )
        if (foundLocalization) {
          await this.chooseLocalization(foundLocalization)
        }
      }
    },
    async viewDoctorVisits(doctor) {
      const vm = this
      await vm.$db.setItem('clinic-doctor-visits-info', {
        selectedDoctor: doctor,
        selectedDate: vm.selectedDate,
        selectedLocalization: vm.selectedLocalization
      })
      await vm.$router.push({ name: 'clinic-doctor-visits' })
    },
    purge() {
      this.doctorsList = []
    },
    async update(date) {
      const vm = this
      vm.isBusy = true
      vm.selectedDate = date
      await vm.fetchDoctorsList(date, vm.selectedLocalization.apiKey)
      vm.isBusy = false
    },
    async fetchDoctorsList(date, apiKey) {
      const vm = this

      const response = await vm.$http.post('e-zacma/doctors-for-day', {
        date: date.date,
        api_key: apiKey
      })

      vm.doctorsList = response.data
    },
    async clearSelectedLocalization() {
      const vm = this
      vm.selectedLocalization = undefined
      await vm.$db.removeItem('previous_selected_localization')
      vm.allowed = []
    },
    async chooseLocalization(item) {
      const vm = this
      vm.selectedLocalization = item
      await vm.$db.setItem('previous_selected_localization', item)
    }
  }
}
</script>
<style scoped lang="scss">
.flex-panel {
  display: flex;
  gap: 10px;

  & > div:first-child {
    flex: 1;
  }
}

.spinner-container {
  .spinner {
    display: block;
    margin: 0 auto;
  }
}

.day-picker-container {
  overflow-y: auto;
  height: calc(100vh - (208px + var(--safe-area-inset-top) * 0.65) - 115px - 0.5rem);
  padding-bottom: 20px;
}
</style>
