function validatePesel(pesel) {
  if (pesel.length !== 11) {
    return false
  }

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
  let sum = 0

  for (let i = 0; i < 10; i++) {
    sum += weights[i] * parseInt(pesel.charAt(i), 10)
  }

  sum %= 10
  sum = (10 - sum) % 10 // Poprawne obliczenie cyfry kontrolnej

  if (sum !== parseInt(pesel.charAt(10), 10)) {
    return false
  }

  return true
}

export function calculateAge(pesel) {
  if (!validatePesel(pesel)) {
    return -1
  }

  const year = parseInt(pesel.substring(0, 2), 10)
  const month = parseInt(pesel.substring(2, 4), 10)
  const day = parseInt(pesel.substring(4, 6), 10)

  let birthdate

  if (month > 80) {
    birthdate = new Date(year + 1800, month - 80 - 1, day)
  } else if (month > 60) {
    birthdate = new Date(year + 2200, month - 60 - 1, day)
  } else if (month > 40) {
    birthdate = new Date(year + 2100, month - 40 - 1, day)
  } else if (month > 20) {
    birthdate = new Date(year + 2000, month - 20 - 1, day)
  } else {
    birthdate = new Date(year + 1900, month - 1, day)
  }

  const today = new Date()
  let age = today.getFullYear() - birthdate.getFullYear()
  const m = today.getMonth() - birthdate.getMonth()

  if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
    // eslint-disable-next-line no-plusplus
    age--
  }

  return age
}
