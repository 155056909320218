export default class Image {
  static callback = null

  static createImageObject() {
    return document.createElement('img')
  }

  static applyPolyfill() {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value(callback, type, quality) {
        const dataURL = this.toDataURL(type, quality).split(',')[1]
        setTimeout(() => {
          const binStr = atob(dataURL)
          const len = binStr.length
          const arr = new Uint8Array(len)
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i)
          }
          callback(new Blob([arr], { type: type || 'image/png' }))
        })
      }
    })
  }

  static getImagePreparedToUpload(file, callback) {
    if (!HTMLCanvasElement.prototype.toBlob) {
      Image.applyPolyfill()
    }
    Image.callback = callback
    Image.getBase64(file, Image.handleBase64)
  }

  static handleBase64(base64) {
    if (false) {
      // Image.rotateImage(base64, Image.compressAndConvertToBlob)
    } else {
      Image.compressAndConvertToBlob(base64)
    }
  }

  static compressAndConvertToBlob(base64) {
    const img = Image.createImageObject()
    img.src = base64
    img.onload = () => {
      const elem = document.createElement('canvas')

      const width = 1190
      const ratio = img.width / width
      const height = img.height / ratio

      elem.width = width
      elem.height = height

      const ctx = elem.getContext('2d')
      // img.width and img.height will contain the original dimensions
      ctx.drawImage(img, 0, 0, width, height)
      ctx.canvas.toBlob(
        (blob) => {
          Image.callback(blob)
        },
        'image/jpeg',
        0.5
      )
    }
  }

  // eslint-disable-next-line class-methods-use-this
  static getBase64(file, callback) {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const { result } = reader
      callback(result)
    }

    reader.onerror = (e) => {
      console.error(e.message)
    }
  }

  static rotateImage(base64Image, callback) {
    const vm = this
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const image = Image.createImageObject()
    image.src = base64Image

    image.onload = () => {
      const width = 1200
      const ratio = image.width / width
      const height = image.height / ratio

      canvas.width = height
      canvas.height = width

      ctx.translate(height, 0)
      ctx.rotate((90 * Math.PI) / 180)
      ctx.drawImage(image, 0, 0, width, height)
      ctx.save()

      callback(canvas.toDataURL())
    }
  }

  static isIphone() {
    return false
    // return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
  }
}
