<template>
  <div v-if="withContainer" class="d-flex justify-content-center m-4" :class="classNames">
    <b-spinner label="Ładowanie..." class="spinner" :size="size" type="grow"></b-spinner>
  </div>
  <b-spinner v-else label="Ładowanie..." class="spinner" :size="size" type="grow"></b-spinner>
</template>

<script>
export default {
  name: 'UnisonSpinner',
  props: ['withContainer', 'classNames', 'size']
}
</script>
