<template>
  <div class="patient page-container">
    <div v-show="!finished" class="panel form" :class="{ collapsed: beforeSearch }">
      <div class="title mb-2">Dane pacjenta</div>
      <b-form v-if="beforeSearch" @submit.prevent="findPatient">
        <b-form-group
          id="social-security-number-input-group"
          label="PESEL:"
          label-for="social-security-number"
          description="Podaj PESEL pacjenta w celu wyszukania jego danych."
        >
          <b-form-input
            id="social-security-number"
            v-model="socialSecurityNumber"
            type="text"
            pattern="[0-9]{11}"
            required
            inputmode="decimal"
            placeholder="Wpisz numer PESEL"
            :disabled="busy"
            v-mask="'###########'"
            autocomplete="off"
            minlength="11"
            maxlength="11"
            v-on:input="formatSocialSecurityNumber"
          ></b-form-input>
        </b-form-group>

        <button v-if="!busy" type="submit" class="btn button pink">Wyszukaj pacjenta</button>
        <button v-else class="btn button pink" disabled>
          <b-spinner variant="light" type="grow" small></b-spinner>
        </button>
      </b-form>

      <b-form v-else @submit.prevent="register">
        <b-form-group
          id="social-security-number-input-group-2"
          label="PESEL:"
          label-for="social-security-number-2"
        >
          <b-form-input
            id="social-security-number-2"
            v-model="socialSecurityNumber"
            type="text"
            pattern="[0-9]*"
            required
            placeholder="Wpisz numer PESEL"
            v-mask="'###########'"
            autocomplete="off"
            :disabled="busy"
            minlength="11"
            maxlength="11"
            @focus="resetPatientSearch"
          ></b-form-input>
        </b-form-group>

        <div class="transition-group animated" :class="{ fadeInDown: !beforeSearch }">
          <b-form-group id="name-input-group" label="Imię:" label-for="name">
            <b-form-input
              id="name"
              v-model="patient.name"
              type="text"
              required
              placeholder="Wpisz imię pacjenta"
              autocomplete="off"
              :disabled="busy"
            ></b-form-input>
          </b-form-group>

          <b-form-group id="lastname-input-group" label="Nazwisko:" label-for="lastname">
            <b-form-input
              id="lastname"
              v-model="patient.lastname"
              type="text"
              required
              placeholder="Wpisz nazwisko pacjenta"
              autocomplete="off"
              :disabled="busy"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="phone-input-group"
            label="Numer telefonu:"
            label-for="phone"
            description="Podany numer telefonu musi być numerem komórkowym"
          >
            <b-form-input
              id="phone"
              v-model="patient.phone"
              type="tel"
              required
              minlength="11"
              maxlength="11"
              pattern="^[0-9]{3}-[0-9]{3}-[0-9]{3}$"
              placeholder="Wpisz numer telefonu pacjenta"
              autocomplete="off"
              :disabled="busy"
              v-on:input="formatPhoneNumber"
            ></b-form-input>
          </b-form-group>

          <b-form-group v-if="withNotes" label-for="notes-textarea" label="Uwagi">
            <b-form-textarea
              id="notes-textarea"
              style="height: 100px; font-size: 15px"
              v-model="additionalInfo"
              placeholder="Wpisz uwagi"
              maxlength="100"
              :disabled="busy"
            ></b-form-textarea>
          </b-form-group>

          <div v-if="busy === false">
            <button
              v-if="!withNotes"
              @click="addNotes"
              type="button"
              class="btn button outlined mb-2"
            >
              Dodaj uwagi
            </button>
            <button v-if="consent === 'sms'" type="submit" class="btn button pink">
              Wyślij SMS z informacją o rezerwacji terminu
            </button>
            <button v-else type="submit" class="btn button pink">Zarejestruj</button>
          </div>
          <button v-else class="btn button pink" disabled>
            <b-spinner variant="light" type="grow" small></b-spinner>
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import t from 'izitoast'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Patient',
  async mounted() {
    const vm = this
    document
      .getElementById('social-security-number')
      .addEventListener('focus', vm.resetPatientSearch)
    vm.consent = await vm.$db.getItem('consent_type')
  },
  data() {
    return {
      busy: false,
      beforeSearch: true,
      finished: false,
      socialSecurityNumber: '',
      patient: {
        social_security_number: '',
        name: '',
        lastname: '',
        phone: '',
        IDPacjent: 0
      },
      consent: '',
      additionalInfo: 'Bez uwag',
      withNotes: false
    }
  },
  watch: {
    socialSecurityNumber(value) {
      const vm = this
      vm.patient.social_security_number = value

      if (value.length === 11) {
        // if (vm.isValidSocialSecurityNumber(value)) {
        //   vm.changeSocialSecurityInputDesc('Liczba kontrolna PESELu prawidłowa.', 'success')
        // } else {
        //   vm.changeSocialSecurityInputDesc(
        //     'Liczba kontrolna PESELu nie jest prawidłowa. Sprawdź poprawność lub jeżeli jesteś pewny/a poprawności, zignoruj to powiadomienie.',
        //     'warning'
        //   )
        // }
      } else {
        vm.changeSocialSecurityInputDesc(
          'Podaj PESEL pacjenta w celu wyszukania jego danych.',
          'muted'
        )
      }
    },
    patient(value) {
      this.socialSecurityNumber = value.social_security_number
    }
  },
  methods: {
    formatSocialSecurityNumber() {
      const vm = this
      vm.socialSecurityNumber = vm.socialSecurityNumber.replace(/\D/g, '')
      vm.socialSecurityNumber = vm.socialSecurityNumber.slice(0, 11)
    },
    formatPhoneNumber() {
      let number = this.patient.phone.replace(/\D/g, '')
      number = number.slice(0, 9)
      const parts = []
      for (let i = 0; i < number.length; i += 3) {
        parts.push(number.slice(i, i + 3))
      }
      this.patient.phone = parts.join('-')
    },
    addNotes() {
      const vm = this
      vm.additionalInfo = ''
      vm.withNotes = true
    },
    changeSocialSecurityInputDesc(message, type) {
      const inputDescriptionId = 'social-security-number-input-group__BV_description_'
      const inputDescription = document.getElementById(inputDescriptionId)
      const className = `text-${type}`

      if (inputDescription === null) return

      // TODO: Make it a loop
      inputDescription.classList.remove('text-muted')
      inputDescription.classList.remove('text-warning')
      inputDescription.classList.remove('text-success')
      inputDescription.classList.remove('text-danger')

      inputDescription.innerText = message
      inputDescription.classList.add(className)
    },
    isValidSocialSecurityNumber(string) {
      const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
      let sum = 0
      const controlNumber = parseInt(string.substring(10, 11), 10)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < weight.length; i++) {
        sum += parseInt(string.substring(i, i + 1), 10) * weight[i]
      }
      sum %= 10
      return 10 - sum === controlNumber
    },
    async findPatient() {
      const vm = this
      if (vm.socialSecurityNumber.length !== 11) {
        vm.changeSocialSecurityInputDesc('Numer PESEL musi mieć 11 znaków.', 'danger')
        return
      }
      // if (!vm.isValidSocialSecurityNumber(vm.socialSecurityNumber)) {
      //   vm.changeSocialSecurityInputDesc('Liczba kontrolna PESELu nie jest prawidłowa. Sprawdź poprawność lub jeżeli jesteś pewny/a poprawności, zignoruj to powiadomienie.', 'warning')
      //   return
      // }
      vm.busy = true
      vm.$http
        .post('e-zacma/find-patient', vm.patient)
        .then(async (response) => {
          if (response.data.social_security_number !== '') {
            let { IDPacjent } = response.data
            if (IDPacjent === null || IDPacjent === undefined) {
              IDPacjent = response.data.unison_id
            }
            if (!(await vm.canBeRegistered(IDPacjent))) return
            vm.patient = response.data
          }
          vm.initFullForm()
        })
        .catch(() => {
          vm.error('Wystapił błąd podczas próby wyszukania pacjenta.')
          vm.busy = false
        })
    },
    async canBeRegistered($patientID) {
      const vm = this
      if ($patientID === 6131) return true
      if (vm.consent === 'already-agreed' && parseInt($patientID, 10) === 0) {
        vm.canNotRegisterNewPatientWithoutConsent()
        return false
      }
      if (parseInt($patientID, 10) === 0) return true
      const IDAnkietaPytanie = await vm.$db
        .getItem('temporary_visit_info')
        .then((value) => value.visit_type)
      const status = await vm.$http
        .post('e-zacma/is-patient-already-registered', {
          IDPacjent: $patientID,
          IDAnkietaPytanie
        })
        .then(() => false)
        .catch(() => true)
      if (status === false) vm.registerBlockedModal()
      return status
    },
    canNotRegisterNewPatientWithoutConsent() {
      const vm = this
      vm.$bvModal
        .msgBoxConfirm(
          'Nie znaleziono pacjenta. Brak możliwości rejestracji nowego pacjenta bez zgody.',
          {
            title: 'Rejestracja zablokowana',
            okVariant: 'danger',
            okTitle: 'Przerwij rejestracje',
            cancelTitle: 'Zmień dane pacjenta',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            hideHeaderClose: true,
            centered: true
          }
        )
        .then((decision) => {
          if (decision) {
            vm.$router.push({ name: 'home' })
          } else {
            vm.busy = false
            vm.resetPatientSearch()
          }
        })
        .catch(() => {
          vm.$router.push({ name: 'home' })
        })
    },
    registerBlockedModal() {
      const vm = this
      vm.$bvModal
        .msgBoxConfirm(
          'Podany pacjent jest już zarejestrowany na ten typ kwalifikacji w wybranym zakresie.',
          {
            title: 'Rejestracja zablokowana',
            okVariant: 'danger',
            okTitle: 'Przerwij rejestracje',
            cancelTitle: 'Zmień dane pacjenta',
            noCloseOnBackdrop: true,
            noCloseOnEsc: true,
            hideHeaderClose: true,
            centered: true
          }
        )
        .then((decision) => {
          if (decision) {
            vm.$router.push({ name: 'home' })
          } else {
            vm.busy = false
            vm.resetPatientSearch()
          }
        })
        .catch(() => {
          vm.$router.push({ name: 'home' })
        })
    },
    initFullForm() {
      const vm = this
      vm.beforeSearch = false
      vm.busy = false
    },
    resetPatientSearch() {
      const vm = this
      vm.beforeSearch = true
    },
    hideForms() {
      this.busy = true
    },
    async register() {
      const vm = this

      const registerInput = async () => {
        const { patient, consent } = vm
        const account = await vm.$db.getItem('account')
        const visitInfo = await vm.$db.getItem('temporary_visit_info')

        return {
          consent,
          api_key: visitInfo.api_key,
          user_id: account.user_id,
          slot_reservation_id: visitInfo.id,
          date: visitInfo.date,
          time: visitInfo.time,
          paid_online: visitInfo.payment_online,
          paid_amount: visitInfo.price,
          payment_id: 'portal',
          is_nfz: visitInfo.nfz_available,
          doctor_id: visitInfo.doctor_id,
          language: 'pl',
          salon_id: visitInfo.IDSalon,
          reason_id: visitInfo.visit_type,
          patient: {
            unison_id: patient.IDPacjent,
            name: patient.name,
            last_name: patient.lastname,
            country_code: '48',
            phone: !patient.phone || patient.phone === '' ? '000-000-000' : patient.phone,
            additional_info: `${vm.additionalInfo} - Nr tel. pacjenta: ${patient.phone}`,
            terms_accepted: true,
            social_security_number: patient.social_security_number
          }
        }
      }
      vm.busy = true
      // const reservation = await vm.fetchVisitDetails()
      // if (reservation === null) {
      //   vm.error('Wystapił błąd podczas próby zarejestrowania pacjenta. Termin został już zarejestrowany.')
      //   vm.$router.push({ name: 'calendar' })
      //   return
      // }

      const input = await registerInput()
      vm.$http
        .post('e-zacma/register', input)
        .then(async (response) => {
          vm.hideForms()
          if (vm.consent === 'sms') {
            vm.success(
              'SMS z informacją został wysłany do pacjenta. Zostaniesz poinformowany/a gdy potwierdzi rejestracje.'
            )
          } else {
            vm.success('Pacjent został zarejestrowany poprawnie.')
          }
          await vm.$db.removeItem('temporary_visit_info')
        })
        .catch(() => {
          vm.error('Wystapił błąd podczas próby zarejestrowania pacjenta.')
        })
        .finally(() => {
          setTimeout(() => {
            vm.$router.push({ name: 'home' })
          }, 2000)
        })
    },
    async fetchVisitDetails() {
      const vm = this
      const id = await vm.$db.getItem('temporary_visit_info').then((value) => value.id)
      const doctor = await vm.$db.getItem('temporary_visit_info').then((value) => value.doctor)
      const apiKey = await vm.$db.getItem('temporary_visit_info').then((value) => value.api_key)
      const IDAnkietaPytanie = await vm.$db
        .getItem('temporary_visit_info')
        .then((value) => value.visit_type)
      const account = await vm.$db.getItem('account').then((item) => item)
      let reservation = null
      await vm.$http
        .post('e-zacma/fetch-visit-details', { id })
        .then((response) => {
          const { data } = response
          if (data.visit_id !== undefined) {
            reservation = {
              reservation_id: data.visit_id,
              visit_id: data.visit_id,
              IDAnkietaPytanie,
              Termin: data.Termin,
              Godzina: data.Godzina,
              IDUsers: data.IDUsers,
              IDSalon: data.IDSalon,
              NFZ: data.NFZ,
              IdLok: data.IdLok,
              IdKomOrg: data.IdKomOrg,
              consultant: `${account.title} ${account.name} ${account.last_name}`,
              doctor,
              apiKey,
              additional_info: vm.additionalInfo
            }
          }
        })
        .catch(() => {
          vm.$toast.error({
            message: 'Wystapił błąd podczas przetwarzania żądania'
          })
        })
      return reservation
    },
    success(message) {
      this.toast(message, 'fa-check-circle', 'bottomCenter', 'dark', '#50c9c3')
    },
    error(message) {
      this.toast(message, 'fa-exclamation-circle', 'bottomCenter', 'dark', '#c72433')
    },
    toast(title, icon, position, theme, backgroundColor) {
      t.show({
        title,
        // titleSize: '16px',
        // titleLineHeight: '20px',
        close: true,
        icon: `fas ${icon}`,
        position,
        layout: 1,
        theme,
        backgroundColor,
        timeout: 10000,
        displayMode: 1
      })
    }
  }
}
</script>

<style scoped lang="scss">
.panel.form {
  transition: max-height 0.25s ease-out;
  height: auto;
  margin-bottom: 20px;

  &.collapsed {
    max-height: 310px;
  }
}

.transition-group {
  animation-duration: 0.27s;
}
</style>

<style lang="scss">
.form-group .form-text.text-warning,
.form-group .form-text.text-success {
  padding-top: 5px;
  font-size: 90%;
}
</style>
