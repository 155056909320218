const BusyIndicator = {
  install(Vue, options = {}) {
    Vue.prototype.$busy = function (toggle, transparent = true) {
      const preloader = document.getElementById('pre-loader')

      if (preloader === null) return

      if (transparent) {
        preloader.style.background = 'rgba(255,255,255,0.66)'
      } else {
        preloader.style.background = '#fff'
      }

      if (toggle) {
        preloader.style.display = 'block'
      } else {
        preloader.style.display = 'none'
      }
    }
  }
}

export default BusyIndicator.install
