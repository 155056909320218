<template>
  <div style="height: 0">
    <div class="document-uploader" v-if="fileData">
      <div class="panel">
        <div class="title pb-2 text-center">
          <slot></slot>
        </div>
        <button class="btn button mt-2" @click="processFile">Wyślij i przejdź dalej</button>
        <button class="btn button outlined mt-2" @click="triggerInput">Wykonaj jeszcze raz</button>
        <button class="btn button outlined mt-2" @click="clearImage">Anuluj</button>
        <div class="panel">
          <div class="subtitle">Podgląd</div>
          <hr class="hr pt-0 mt-0" />
          <img :src="fileData" alt="Podgląd" class="w-100" />
          <div class="row mt-3 mb-0" v-if="fileName">
            <div class="col-6"><label class="">Nazwa dokumentu:</label></div>
            <div class="col-6 mb-0">
              <label class="">{{ fileName }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      ref="inputRef"
      v-on:change="imageChanged"
      type="file"
      :accept="accept"
      name="file-input"
      class="invisible"
    />
  </div>
</template>
<script>
import image from '../plugins/image-manipulation'

export default {
  name: 'ImagePreview',
  props: {
    fileName: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/jpg'
    },
    fileData: {
      type: String,
      default: null
    },
    withFileName: {
      type: Boolean,
      default: false
    },
    sendBlobFile: {
      type: Function
    }
  },
  data() {
    return {
      file: null,
      render: true
    }
  },
  methods: {
    triggerInput() {
      this.$refs.inputRef.click()
    },
    imageChanged(event) {
      const vm = this
      const input = event.target
      if (input.files && input.files[0]) {
        // eslint-disable-next-line prefer-destructuring
        vm.file = input.files[0]
        const reader = new FileReader()

        reader.readAsDataURL(vm.file)
        reader.onload = () => {
          const { result } = reader
          if (false) {
            // image.rotateImage(result, (rotated) => {
            //   vm.$emit('imageFileChanged', rotated)
            //   vm.documentNameModal()
            // })
          } else {
            vm.$emit('imageFileChanged', result)
            vm.documentNameModal()
          }
        }
        reader.onerror = (e) => {
          vm.$error('Wysłanie pliku nie powiodło się.')
        }
      }
    },
    clearImage() {
      this.$emit('imageFileChanged', null)
      this.$emit('imageNameChanged', null)
      this.resetInput()
    },
    processFile() {
      const vm = this
      image.getImagePreparedToUpload(vm.file, (blob) => {
        vm.$emit('processFile', blob)
        vm.resetInput()
      })
    },
    resetInput() {
      this.$refs.inputRef.value = null
    },
    documentNameModal() {
      const vm = this
      if (vm.withFileName) {
        vm.$modalPrompt('Wprowadź nazwę dokumentu', 'Nazwa dokumentu', (name) => {
          if (name.length === 0) name = 'Bez nazwy'
          vm.$emit('imageNameChanged', name)
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
.document-uploader {
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 55px;
  left: 0;
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  overflow: auto;
  padding-bottom: 55px;
}
</style>
