<template>
  <div class="page-container">
    <div class="panel profile">
      <header class="row">
        <div class="image col-3">
          <div class="div">
            <font-awesome-icon icon="user" />
          </div>
        </div>
        <div class="col-9">
          <div class="name">{{ account.title }} {{ account.name }} {{ account.last_name }}</div>
          <div class="license-number">{{ account.license_number }}</div>
        </div>
        <div class="type">{{ getAccountType(user.account_type) }}</div>
      </header>
      <hr />
      <article class="info">
        <div class="row">
          <div class="col-12">
            <div class="icon"><font-awesome-icon icon="mobile" /></div>
            {{ account.phone }}
          </div>
          <div class="col-12">
            <div class="icon"><font-awesome-icon icon="envelope" /></div>
            {{ user.email }}
          </div>
          <div v-if="false" class="col-12">
            <div class="icon"><font-awesome-icon icon="money-check" /></div>
            {{ account.iban }}
          </div>
        </div>
      </article>
    </div>

    <div v-if="false" class="invoices-container panel">
      <div class="subtitle">Rozliczenia</div>
      <div class="mt-2">
        <b-tabs content-class="mt-3" fill>
          <b-tab class="queue" title="Oczekujące na operacje" active>
            <p v-if="preSurgeryConsultations.length === 0" class="pt-3 text-center">Brak</p>
            <div class="item panel" v-bind:key="item.id" v-for="item in preSurgeryConsultations">
              <div class=""><b>Pacjent:</b> {{ item.patient }}</div>
              <div class=""><b>Zabieg:</b> {{ item.visit_type }}</div>
              <div class="date"><b>Data zabiegu:</b> {{ item.date }}</div>
              <div class="price"><b>Cena za konsultacje:</b> {{ item.price }}zł</div>
            </div>
          </b-tab>
          <b-tab class="queue" title="Oczekujące na rozliczenie">
            <p v-if="unpaidConsultations.length === 0" class="pt-3 text-center">Brak</p>
            <div class="item panel" v-bind:key="item.id" v-for="item in unpaidConsultations">
              <div class=""><b>Pacjent:</b> {{ item.patient }}</div>
              <div class=""><b>Zabieg:</b> {{ item.visit_type }}</div>
              <div class="date"><b>Data zabiegu:</b> {{ item.date }}</div>
              <div class="price"><b>Do zapłaty:</b> {{ item.price }}zł</div>
            </div>
          </b-tab>
          <b-tab class="paid" title="Rozliczone">
            <p v-if="paidConsultations.length === 0" class="pt-3 text-center">Brak</p>
            <div class="item panel" v-bind:key="item.id" v-for="item in paidConsultations">
              <div class=""><b>Pacjent:</b> {{ item.patient }}</div>
              <div class=""><b>Zabieg:</b> {{ item.visit_type }}</div>
              <div class="date"><b>Data zabiegu:</b> {{ item.date }}</div>
              <div class="price"><b>Zapłacono:</b> {{ item.price }}zł</div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  async mounted() {
    const vm = this
    vm.account = await vm.$db.getItem('account')
    vm.user = await vm.$db.getItem('user')
    // vm.fetchPreSurgeryConsultations()
    // vm.fetchUnpaidConsultations()
    // vm.fetchPaidConsultations()
  },
  data() {
    return {
      checked: false,
      account: {},
      user: {},
      preSurgeryConsultations: [],
      unpaidConsultations: [],
      paidConsultations: []
    }
  },
  methods: {
    getAccountType(accountType) {
      switch (accountType) {
        case 'doctor':
          return 'Personel medyczny kliniki z funkcją konsultanta'
        case 'specialist':
          return 'Personel medyczny kliniki'
        case 'consultant':
          return 'Kontultant'
        case 'clinic_staff':
          return 'Personel kliniki'
        case 'clinic_staff_with_consultant_feature':
          return 'Personel kliniki z funkcją konsultanta'
        case 'clinic_office':
          return 'Gabinet'
        default:
          return 'Nieznany'
      }
    },
    async fetchPreSurgeryConsultations() {
      const vm = this
      vm.preSurgeryConsultations = await vm.$http
        .post('e-zacma/doctor/get-pre-surgery-consultations')
        .then((response) => response.data)
    },
    async fetchUnpaidConsultations() {
      const vm = this
      vm.unpaidConsultations = await vm.$http
        .post('e-zacma/doctor/get-unpaid-consultations')
        .then((response) => response.data)
    },
    async fetchPaidConsultations() {
      const vm = this
      vm.paidConsultations = await vm.$http
        .post('e-zacma/doctor/get-paid-consultations')
        .then((response) => response.data)
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/presets';
@import url('https://fonts.googleapis.com/css?family=Lato:400&display=swap');

.profile {
  // position: fixed;
  // width: 100%;
  // top: 100px;
  padding: 15px;

  header {
    .image {
      padding-left: 20px;

      > div {
        background: rgba($pink, 0.5);
        width: 60px;
        height: 60px;
        text-align: center;
        font-size: 30px;
        line-height: 60px;
        border-radius: $border-radius;
        color: $white;
      }
    }

    .name,
    .license-number {
      line-height: 30px;
    }

    .type {
      margin-left: 10px;
      margin-top: 10px;
    }

    .name {
      font-size: 18px;
    }

    .license-number {
      font-size: 14px;
    }
  }

  article.info {
    color: $grey-2;

    .col-12 > * {
      float: left;
    }

    .col-12 .icon {
      width: 25px;
    }
  }
}

.invoices-container {
  .item {
    box-shadow: -4px -4px 12px 2px rgba(0, 0, 0, 0.1), 4px 4px 12px 2px rgba(45, 78, 255, 0.1);

    .name {
      font-size: 18px;
    }

    .visit-type {
      font-size: 13px;
    }
  }

  .queue .item {
    border-right: 7px solid $myellow;
  }

  .paid .item {
    border-right: 7px solid $pink;
  }
}
</style>
