<template>
  <div class="page-container">
    <!--    <b-alert :show="showInfoAlert" dismissible>-->
    <!--      <h4 class="alert-heading">Potrzebujesz pomocy?</h4>-->
    <!--      <p>Zadzwoń do nas: {{ contactNumber }}</p>-->
    <!--    </b-alert>-->
    <div class="panel">
      <div class="title">Logowanie do aplikacji</div>
      <b-alert v-if="error" show variant="danger mt-1 mb-1">{{ errorText }}</b-alert>
      <b-form v-if="beforeSMS" @submit.prevent="sendCode">
        <b-form-group id="email-input-group" label="Email:" label-for="email">
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="Wpisz email"
            :disabled="busy"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <button v-if="!busy" type="submit" class="btn button pink">Wyślij hasło jednorazowe</button>
        <button v-else class="btn button pink" disabled>
          <b-spinner variant="light" type="grow" small></b-spinner>
        </button>
      </b-form>
      <b-form v-else @submit.prevent="login">
        <b-form-group id="email-input-group-2" label="Email:" label-for="email">
          <b-form-input
            id="email-2"
            v-model="email"
            type="email"
            required
            placeholder="Wpisz email"
            :disabled="!beforeSMS"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="sms-code-input-group" label="Hasło SMS:" label-for="sms-code">
          <b-form-input
            id="sms-code"
            v-model="smsCode"
            type="number"
            pattern="[0-9]*"
            required
            placeholder="Wpisz hasło SMS"
            :disabled="busy"
            autocomplete="off"
          ></b-form-input>
        </b-form-group>

        <button
          @click="resendCode"
          v-if="!busy && error"
          type="submit"
          class="btn button mb-2 outlined"
        >
          Wyślij ponownie
        </button>
        <button v-if="!busy" type="submit" class="btn button pink">Zaloguj</button>
        <button v-else class="btn button pink" disabled>
          <b-spinner variant="light" type="grow" small></b-spinner>
        </button>
      </b-form>
    </div>

    <div class="android-download-button">
      <button @click="downloadAPK" class="btn">
        <font-awesome-icon :icon="['fas', 'tablet']" size="40" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SMSLogin',
  mounted() {
    this.fetchContactNumber()

    const lastLoginEmail = localStorage.getItem('lastLoginEmail')
    if (lastLoginEmail) {
      this.email = lastLoginEmail
    }
  },
  data() {
    return {
      email: '',
      smsCode: '',
      busy: false,
      beforeSMS: true,
      error: false,
      errorText: '',
      contactNumber: '',
      showInfoAlert: false
    }
  },
  methods: {
    async downloadAPK() {
      const response = await this.$http.post('e-zacma/tablets/get-android-tablet-apk-url')
      const link = document.createElement('a')
      link.href = response.data.apk_url
      link.download = 'E-Unison-Tablet.apk'
      link.click()
    },
    resendCode() {
      const vm = this
      vm.$logEvent('resend_code', {})
      vm.sendCode()
    },
    sendCode() {
      const vm = this
      vm.$logEvent('send_code', {})
      const { email } = vm
      vm.error = false
      vm.busy = true
      vm.$http
        .post('/auth/login', { email, login_type: 'sms', send_code: true })
        .then(() => {
          vm.beforeSMS = false
        })
        .catch((e) => {
          console.error(e)
          vm.$error('Podano zły adres email!')
        })
        .finally(() => {
          vm.busy = false
        })
    },
    login() {
      const vm = this
      const { email, smsCode } = vm
      vm.error = false
      vm.busy = true
      if (smsCode === '') {
        vm.errorText = 'Niepoprawne hasło jednorazowe'
        vm.error = true
        return
      }
      vm.$login({ email, smsCode, login_type: 'sms' }).catch((error) => {
        if (error.status === 403) {
          vm.errorText = 'Konto zablokowane'
        } else {
          vm.errorText = 'Niepoprawne hasło jednorazowe'
        }
        vm.error = true
        vm.busy = false
        vm.smsCode = ''
      })
    },
    fetchContactNumber() {
      const vm = this
      // vm.$http.post('auth/get-consultant-info-number').then((response) => {
      //   vm.contactNumber = response.data
      //   vm.showInfoAlert = true
      // })
    }
  }
}
</script>

<style scoped>
.android-download-button {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  height: 50px;
  width: 50px;
}
</style>
