import Vue from 'vue'
import Router from 'vue-router'
import ClinicDoctorVisits from '@/views/Clinic/ClinicDoctorVisits.vue'
import ClinicDoctors from './views/Clinic/ClinicDoctors.vue'
import Home from './views/Home.vue'
import Calendar from './views/Register/Date.vue'
import Patient from './views/Patients/Single.vue'
import PatientData from './views/Register/Patient.vue'
import PatientsList from './views/Patients/List.vue'
import Consent from './views/Register/Consent.vue'
import Account from './views/Account/Index.vue'
import SMSLogin from './views/Login/SMSLogin.vue'
import VisitType from './views/Register/VisitType.vue'
import VisitsToReview from './views/Patients/VisitsToReview.vue'
import Visits from './views/Register/Visits.vue'
// import DetailedVisitView from './views/Patients/Visit.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
        depth: 0
      }
    },
    {
      path: '/visit-type',
      name: 'visit-type',
      component: VisitType,
      meta: {
        auth: true,
        depth: 1,
        previous: 'home'
      }
    },
    {
      path: '/clinic-doctors',
      name: 'clinic-doctors',
      component: ClinicDoctors,
      meta: {
        auth: true,
        depth: 1,
        previous: 'home'
      }
    },
    {
      path: '/clinic-doctor-visits',
      name: 'clinic-doctor-visits',
      component: ClinicDoctorVisits,
      meta: {
        auth: true,
        depth: 2,
        previous: 'clinic-doctors'
      }
    },
    {
      path: '/clinic-doctor-visit-details',
      name: 'clinic-doctor-visit-details',
      // component: DetailedVisitView,
      component: () => import('./views/Clinic/ClinicDoctorVisitDetail.vue'),
      meta: {
        auth: true,
        depth: 3,
        previous: 'clinic-doctor-visits'
      }
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: Calendar,
      // component: () => import(/* webpackChunkName: "register" */'./views/Register/Date.vue'),
      meta: {
        auth: true,
        depth: 2,
        previous: 'visit-type'
      }
    },
    {
      path: '/consent',
      name: 'consent',
      component: Consent,
      meta: {
        auth: true,
        depth: 3,
        previous: 'calendar'
      }
    },
    {
      path: '/patient-data',
      name: 'patient-data',
      component: PatientData,
      meta: {
        auth: true,
        depth: 4,
        previous: 'consent'
      }
    },
    {
      path: '/patients',
      name: 'patients',
      component: PatientsList,
      meta: {
        auth: true,
        depth: 2,
        previous: 'home'
      }
    },
    {
      path: '/patient',
      name: 'patient',
      component: Patient,
      meta: {
        auth: true,
        depth: 3,
        previous: 'patients'
      }
    },
    {
      path: '/visit-detailed-view',
      name: 'visit-detailed-view',
      // component: DetailedVisitView,
      component: () => import('./views/Patients/Visit.vue'),
      meta: {
        auth: true,
        depth: 4,
        previous: 'patient'
      }
    },
    {
      path: '/doctor-visits',
      name: 'doctor-visits',
      component: () => import('./views/Specialist/Visits.vue'),
      meta: {
        auth: true,
        depth: 1,
        previous: 'home'
      }
    },
    {
      path: '/doctor-visit-details',
      name: 'doctor-visit-details',
      // component: DetailedVisitView,
      component: () => import('./views/Specialist/VisitDetail.vue'),
      meta: {
        auth: true,
        depth: 2,
        previous: 'doctor-visits'
      }
    },
    {
      path: '/doctor-patient-search',
      name: 'doctor-patient-search',
      component: () => import('./views/Specialist/SearchPatient.vue'),
      meta: {
        auth: true,
        depth: 1,
        previous: 'home'
      }
    },
    {
      path: '/doctor-patient-details',
      name: 'doctor-patient-details',
      component: () => import('./views/Specialist/PatientDetails.vue'),
      meta: {
        auth: true,
        depth: 2,
        previous: 'doctor-patient-search'
      }
    },
    {
      path: '/doctor-patient-visit',
      name: 'doctor-patient-visit',
      // component: DetailedVisitView,
      component: () => import('./views/Specialist/VisitDetail.vue'),
      meta: {
        auth: true,
        depth: 3,
        previous: 'doctor-patient-details'
      }
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        auth: true,
        depth: 2,
        previous: 'home'
      }
    },
    {
      path: '/visits-to-review',
      name: 'visits-to-review',
      component: VisitsToReview,
      meta: {
        auth: true,
        depth: 2,
        previous: 'home'
      }
    },
    {
      path: '/visits',
      name: 'visits',
      component: Visits,
      meta: {
        auth: true,
        depth: 2,
        previous: 'home'
      }
    },
    {
      path: '/sms-login',
      name: 'sms-login',
      component: SMSLogin,
      meta: {
        depth: -1,
        onlyUnauthorized: true
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
