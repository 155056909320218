<template>
  <div>
    <ImagePreview
      :file-data="form.image"
      :ref="imagePreviewRef"
      @imageFileChanged="imageChanged"
      @processFile="sendFile"
    >
      Wysyłanie zgody pacjenta
    </ImagePreview>
    <div class="consent page-container" v-if="!form.image">
      <div class="panel">
        <div class="title pb-2">Możliwości rejestracji</div>
        <div v-if="isClinicStaffConsultantOrSpecialistConsultant">
          <button @click="next('no-consent')" class="btn button">Zgoda niewymagana</button>
        </div>
        <hr v-if="isClinicStaffConsultantOrSpecialistConsultant" class="hr pt-3 mt-0" />
        <div class="subtitle">Pozyskaj zgodę i zarejestruj pacjenta</div>
        <div class="description">
          W celu rejestracji wizyty wymagana jest zgoda pacjenta na przetwarzanie danych osobowych
          oraz na udostępnienie dokumentacji medycznej. Zrób zdjęcie zgody, a zostanie ono wysłane
          razem z rejestracją.
        </div>
        <button class="button pink btn" @click="addDocumentImage">Zdjęcie zgody pacjenta</button>
        <div v-if="sms_receive_available">
          <hr class="hr pt-3 mt-0" />
          <div class="subtitle">Dokonaj rezerwacji bez rejestracji na wizytę</div>
          <div class="description">
            Po wpisaniu danych pacjenta, wyślij SMS z informacją o rezerwacji terminu aby pacjent
            mógł sam potwierdzić rezerwacje odpowiedzią SMS o treści "Potwierdzam", która
            jednocześnie będzie potraktowana jako zgoda.
          </div>
          <button @click="next('sms')" class="btn button pink">Potwierdzenie przez SMS</button>
        </div>
        <hr class="hr pt-3 mt-1" />
        <div class="subtitle">Dokonaj rejestracji na podstawie już posiadanej zgody</div>
        <div class="description">
          Jeżeli ten pacjent był już przez Ciebie rejestrowany wykorzystując aplikacje mobliną,
          ponowne wysłanie zgody nie jest wymagane.
        </div>
        <button @click="next('already-agreed')" class="btn button pink">
          Zgoda już w systemie
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { API } from '@/api/api_endpoints'
import ImagePreview from '../../components/ImagePreview.vue'

export default {
  components: { ImagePreview },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Consent',
  computed: {
    isClinicStaffConsultantOrSpecialistConsultant() {
      return (
        this.accountType === 'clinic_staff_with_consultant_feature' || this.accountType === 'doctor'
      )
    }
  },
  async mounted() {
    const vm = this
    vm.reset()
    const regInfo = await vm.$db.getItem('temporary_visit_info')
    const smsApiConfig = await vm.$db.getItem('smsApiConfig')
    const user = await vm.$db.getItem('user')
    vm.accountType = user.account_type
    const { id } = regInfo
    vm.sms_receive_available = smsApiConfig.sms_receive_available
    vm.form.registration_id = id
    vm.form.image = await vm.$db
      .getItem('consent-picture')
      .then((item) => (item === null ? '' : item))
  },
  data() {
    return {
      imagePreviewRef: 'consentImagePreviewRef',
      sms_receive_available: false,
      busy: false,
      form: {
        registration_id: '',
        image: ''
      },
      accountType: ''
    }
  },
  methods: {
    addDocumentImage() {
      this.$refs[this.imagePreviewRef].triggerInput()
    },
    imageChanged(newImageData) {
      this.form.image = newImageData
    },
    updateImage(image) {
      this.form.image = image
    },
    sendFile(blob) {
      const vm = this
      vm.$busy(true)

      vm.consent = 'consent-upload'
      vm.$db.setItem('consent_type', vm.consent)
      vm.$db.setItem('consent-picture', vm.form.image)

      const formData = new FormData()
      formData.append('registration_id', vm.form.registration_id)
      formData.append('file', blob)

      const config = {
        header: {
          'Content-Type': 'multipart/form-data'
        }
      }
      vm.$http
        .post(API.UPLOAD_CONSENT, formData, config)
        .then(() => {
          vm.$router.push({ name: 'patient-data' })
        })
        .catch((e) => {
          // vm.$sentry.captureMessage('Consent upload failed')
          vm.busy = false
        })
    },
    async next(consent) {
      const vm = this
      vm.$busy(true)
      await vm.$db.setItem('consent_type', consent)
      await vm.$router.push({ name: 'patient-data' })
    },
    async reset() {
      await this.$db.removeItem('consent-picture')
      this.form.image = ''
    }
  }
}
</script>

<style scoped lang="scss">
.consent-image {
  max-width: 100%;
}

.subtitle {
  font-size: 17px;
  text-align: justify;
}

.description {
  font-size: 14px;
}

hr.hr {
  border-top: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style>
