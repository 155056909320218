<template>
  <div>
    <div class="visit-type-selector-container">
      <div :class="isActive('all')" @click="changeVariant('all')">Wszystkie</div>
      <div :class="isActive('nfz')" @click="changeVariant('nfz')">
        <img height="20" src="../assets/NFZ-logo.png" alt="NFZ logo" />
      </div>
      <div :class="isActive('private')" @click="changeVariant('private')">Prywatne</div>
    </div>
    <div class="calendar-container container-fluid panel">
      <header v-show="!noPWT">
        <div v-if="!isYearLoading" class="row month-changer">
          <div class="col-2">
            <div v-if="!(year === minYear && month === minMonth)" class="col-12" @click="prev">
              <font-awesome-icon icon="chevron-left" class="float-left" />
            </div>
          </div>
          <div class="col month p-0">
            {{ months[month] }} {{ year }}
            <div class="visit-count-badge">
              <b-badge v-if="!isBusy" variant="danger">{{ visitsTotal }} dostępnych</b-badge>
            </div>
          </div>
          <div class="col-2" @click="next">
            <font-awesome-icon icon="chevron-right" class="float-right" />
          </div>
        </div>
      </header>
      <div class="body d-flex">
        <div v-show="noPWT" class="mt-2 p-2 col-12">
          <div class="alert alert-danger" role="alert">
            Brak zdefiniowanego PWT. Skontaktuj się z administratorem.
          </div>
        </div>
        <div v-show="isBusy || isYearLoading" class="mt-2 p-2 col-12">
          <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
        </div>
        <div class="mt-2 p-2 col-12 align-items-center d-flex" v-if="dates.length === 0">
          <div class="empty col-12">Brak terminów w tym miesiącu</div>
        </div>
        <div v-else class="scroll-container d-flex">
          <div
            class="d-flex block col-12"
            v-bind:key="dates.indexOf(block)"
            v-for="block in dates"
            :class="{ 'animated fadeIn': !isBusy }"
          >
            <div v-bind:key="date.day" v-for="date of block" class="day-col col-2">
              <div hidden>{{ date }}</div>
              <div
                class="circle d-flex align-items-center align-content-center"
                :class="{ active: date.active }"
              >
                <div @click="selectDate(date)" class="row">
                  <div class="day col-12">{{ new Date(date.date) | moment('dd') }}</div>
                  <div class="number col-12">
                    <div>{{ date.day }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import momentjs from 'moment'
import axios from 'axios'
import { uniqBy } from 'lodash'
import { groupBy } from '@/plugins/groupBy'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Calendar',
  async mounted() {
    await this.cacheSettings()
    await this.restore()
    const refreshNeed = this.$store.state.calendarNeedsUpdate
    if (!refreshNeed.updateNeeded && !refreshNeed.refreshNow) {
      await this.fetchAvailableVisits()
    } else {
      this.isBusy = true
    }
    this.$db.removeItem('consent-picture')
    this.isYearLoading = false
  },
  data() {
    return {
      variant: 'all',
      isBusy: false,
      noPWT: false,
      isYearLoading: true,
      selected: {},
      minMonth: new Date().getUTCMonth(),
      minYear: new Date().getUTCFullYear(),
      year: new Date().getUTCFullYear(),
      month: new Date().getUTCMonth(),
      dates: [],
      months: [
        'Styczeń',
        'Luty',
        'Marzec',
        'Kwiecień',
        'Maj',
        'Czerwiec',
        'Lipiec',
        'Sierpień',
        'Wrzesień',
        'Październik',
        'Listopad',
        'Grudzień'
      ],
      days: ['Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'Sob', 'Ndz'],
      updateTimeout: '',
      selectFirstAvailableTimeout: '',
      visitsTotal: 0,
      settings: [],
      cancelTokenSource: null
    }
  },
  watch: {
    variant() {
      this.$db.setItem('calendar_variant', this.variant)
    },
    month() {
      this.$db.setItem('calendar_month', this.month)
      this.$db.setItem('calendar_year', this.year)
    },
    '$store.state.calendarNeedsUpdate': function (value) {
      const refreshNeed = value
      if (refreshNeed.updateNeeded && refreshNeed.refreshNow) {
        this.fetchAvailableVisits()
        this.$store.commit('calendarNeedToBeRerender', { updateNeeded: false, refreshNow: false })
      }
    }
  },
  methods: {
    isActive(variant) {
      return this.variant === variant ? 'active' : ''
    },
    async getPWTYearAndMonth() {
      this.noPWT = false
      this.isYearLoading = true
      await this.getPWT()

      // eslint-disable-next-line no-case-declarations
      const pwt = await this.$db.getItem('pwt')

      if (pwt === null) {
        this.isYearLoading = false
        this.noPWT = true
        return { year: new Date().getUTCFullYear(), month: new Date().getUTCMonth() }
      }

      // separate month, year from date in this format DD-MM-YYYY
      // eslint-disable-next-line no-case-declarations
      const date = pwt.split('.')
      // eslint-disable-next-line prefer-destructuring
      const year = +date[2]
      const month = +date[1] - 1

      this.isYearLoading = false
      return { year, month }
    },
    async changeVariant(variant) {
      this.noPWT = false
      this.variant = variant

      this.dates = [[]]
      this.$emit('purge')
      this.isBusy = true

      // eslint-disable-next-line default-case
      switch (this.variant) {
        case 'all':
          await this.$db.setItem('is_nfz_available', true)
          break
        case 'nfz':
          await this.$db.setItem('is_nfz_available', true)
          // eslint-disable-next-line no-case-declarations
          const { year, month } = await this.getPWTYearAndMonth()
          if (!this.noPWT) {
            this.year = year
            this.month = month
            this.minMonth = month
            this.minYear = year
          }
          break
        case 'private':
          await this.$db.setItem('is_nfz_available', false)
          break
      }

      if (this.variant !== 'nfz') {
        this.month = new Date().getUTCMonth()
        this.year = new Date().getUTCFullYear()
        this.minMonth = this.month
        this.minYear = this.year
      }

      this.update()
    },
    async getPWT() {
      const { settings } = this
      const response = await this.$http
        .post('e-zacma/visits/getPWT', {
          api_key: settings[0].api_key,
          visit_type_id: +settings[0].visit_type
        })
        .catch((e) => {
          console.log('PWT error', e)
          return { data: { pwt: null } }
        })

      await this.$db.setItem('pwt', response.data.pwt)
    },
    next() {
      const vm = this
      vm.dates = [[]]
      this.$emit('purge')
      // eslint-disable-next-line default-case
      if (vm.month === 11) {
        vm.month = 0
        // eslint-disable-next-line no-plusplus
        vm.year++
      } else {
        // eslint-disable-next-line no-plusplus
        vm.month++
      }

      this.update()
    },
    prev() {
      const vm = this
      vm.dates = [[]]
      this.$emit('purge')
      if (vm.year === vm.minYear && vm.month === vm.minMonth) return
      // eslint-disable-next-line default-case
      if (vm.month === 0) {
        vm.month = 11
        // eslint-disable-next-line no-plusplus
        vm.year--
      } else {
        // eslint-disable-next-line no-plusplus
        vm.month--
      }

      this.update()
    },
    async cacheSettings() {
      const vm = this
      let settings = await vm.$db.getItem('calendar_request_settings')
      if (settings === null) settings = []
      vm.settings = settings
    },
    update() {
      if (this.cancelTokenSource !== null) {
        this.cancelTokenSource.cancel()
        this.cancelTokenSource = null
      }
      this.fetchAvailableVisits()
    },
    refactor(merged) {
      const refactored = []
      let temp = []
      merged.sort((a, b) => (a.day > b.day ? 1 : -1))
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < merged.length; ++i) {
        if ((i + 1) % 7 !== 0) {
          temp.push(merged[i])
        } else {
          refactored.push(temp)
          temp = []
        }
      }
      if (temp.length > 0) refactored.push(temp)
      return refactored
    },
    isIterable(object) {
      if (object === null) {
        return false
      }
      return typeof object[Symbol.iterator] === 'function'
    },
    selectFirstAvailable() {
      const vm = this
      vm.selectFirstAvailableTimeout = setTimeout(() => {
        if (vm.dates.length !== 0) {
          vm.selectDate(vm.dates[0][0])
        }
      }, 100)
    },
    selectDate(selectedDate) {
      const vm = this
      // eslint-disable-next-line no-restricted-syntax
      for (const block of vm.dates) {
        // eslint-disable-next-line no-restricted-syntax
        for (const date of block) {
          date.active = date.date === selectedDate.date
        }
      }
      vm.selected = selectedDate
      this.$emit('dateChanged', selectedDate)
    },
    async fetchVisits(setting, dateFrom, dateTo) {
      const isNFZAvailable = await this.$db.getItem('is_nfz_available')

      const input = {
        api_key: setting.api_key,
        doctor_id: 0,
        visit_type_id: setting.visit_type,
        page_language: 'pl',
        filters: {
          payment_online: false,
          nfz_available: isNFZAvailable,
          price_range: [0, 100000],
          time_range: ['01:00', '24:00'],
          date_range: [dateFrom, dateTo]
        }
      }

      return this.$http
        .post('e-zacma/visits/getAvailable', input, {
          cancelToken: this.cancelTokenSource.token
        })
        .then((response) => ({
          ...response,
          data: {
            ...response.data,
            available_visits: response.data.available_visits
              // eslint-disable-next-line array-callback-return,consistent-return
              .filter((elem) => {
                // eslint-disable-next-line default-case
                switch (this.variant) {
                  case 'all':
                    return true
                  case 'nfz':
                    return elem.nfz_available
                  case 'private':
                    return !elem.nfz_available
                }
              })
              .map((visit) => ({
                ...visit,
                api_key: setting.api_key,
                visit_type: setting.visit_type
              }))
          }
        }))
        .catch(() => {
          if (process.env.NODE_ENV === 'production') {
            // this.$sentry.captureMessage('RO get visits failed')
          }
        })
    },
    async fetchAvailableVisits() {
      this.isBusy = true
      const vm = this
      vm.dates = [[]]
      vm.visitsTotal = 0
      const month = vm.month + 1
      const { year, settings } = vm
      const dateFrom = `${year}-${month}-01`
      const daysInMonth = momentjs(dateFrom, 'YYYY-MM-DD').daysInMonth()
      const dateTo = `${year}-${month}-${daysInMonth}`

      const promises = []

      const cancelTokenIsNull = this.cancelTokenSource === null
      this.cancelTokenSource = cancelTokenIsNull
        ? axios.CancelToken.source()
        : this.cancelTokenSource
      settings.forEach((setting) => {
        promises.push(this.fetchVisits(setting, dateFrom, dateTo))
      })
      const data = await Promise.all(promises)
      const visits = data.map((el) => el && el.data && el.data && el.data.available_visits).flat()
      const uniqueVisits = uniqBy(visits, (elem) => [elem?.time, elem?.doctor_id, elem?.date].join()).filter((el) => el !== undefined)
      vm.visitsTotal = uniqueVisits.length

      const groupedVisits = groupBy(uniqueVisits, 'date')
      await vm.$db.setItem('availableVisits', groupedVisits)

      vm.dates = this.refactor(
        Object.keys(groupedVisits).map((date) => ({
          date,
          day: +momentjs(date, 'YYYY-MM-DD').format('D'),
          active: false
        }))
      )
      this.selectFirstAvailable()
      this.isBusy = false
    },
    async restore() {
      const year = await this.$db.getItem('calendar_year')
      const month = await this.$db.getItem('calendar_month')
      const variant = await this.$db.getItem('calendar_variant')

      if (typeof year === 'number' && typeof month === 'number') {
        this.year = year
        this.month = month
      } else {
        await this.$db.setItem('calendar_month', this.month)
        await this.$db.setItem('calendar_year', this.year)
      }

      if (typeof variant === 'string') {
        this.variant = variant

        if (variant === 'nfz') {
          const { year: minYear, month: minMonth } = await this.getPWTYearAndMonth()
          if (!this.noPWT) {
            this.minYear = minYear
            this.minMonth = minMonth
          }
        }
      } else {
        await this.$db.setItem('calendar_variant', this.variant)
      }

      await this.$db.setItem('is_nfz_available', this.variant !== 'private')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/presets';

.empty {
  text-align: center;
}

.spinner {
  display: block;
  margin: 0 auto;
}

.visit-count-badge {
  margin-top: -7px;
  font-size: 15px;
  line-height: 25px;
}
.visit-type-selector-container {
  display: flex;
  gap: 20px;
  padding-top: 10px;
  justify-content: space-between;

  & > div {
    background-color: #fff;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    padding: 15px;
    border-radius: 10px;
    // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    @include box-shadow();

    cursor: pointer;
  }

  & > div.active {
    border: 2px solid $pink;
  }
}
</style>
