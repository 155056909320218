<template>
  <div
    class="item panel lite-shadow"
    :class="classname(visitInfo.status)"
    @click="openDetailedView(visitInfo)"
  >
    <div class="date font-weight-bolder">
      {{ `${withFullDate ? visitInfo.visit_date : ''} ${visitInfo.visit_time}` }}
    </div>
    <div class="visit-type"><b>Typ:</b> {{ visitInfo.visit_type_name }}</div>
    <div v-if="withPatientInfo && patient" class="name">
      <b>Pacjent:</b> {{ `${patient.first_name} ${patient.last_name}` }}
    </div>
    <div><b>Płatnik:</b> {{ visitInfo.payment_type }}</div>
    <div v-if="withLocalization"><b>Lokalizacja:</b> {{ `${visitInfo.localization}` }}</div>
    <div><b>Status organizacyjny:</b> {{ `${visitInfo.status}` }}</div>
    <div v-if="withDoctorInfo" class="doctor"><b>Specjalista:</b> {{ visitInfo.doctor }}</div>
  </div>
</template>
<script>
export default {
  name: 'VisitItem',
  props: [
    'visitInfo',
    'busy',
    'withPatientInfo',
    'onClick',
    'withLocalization',
    'withFullDate',
    'withDoctorInfo'
  ],
  // eslint-disable-next-line no-empty-function
  async mounted() {
    if (this.visitInfo.patient) {
      this.patient = this.visitInfo.patient
    } else if (this.visitInfo.patinet) {
      this.patient = this.visitInfo.patinet
    }
  },
  data() {
    return {
      isBusy: false,
      patient: {
        first_name: '',
        last_name: ''
      }
    }
  },
  methods: {
    async openDetailedView(visit) {
      if (this.onClick) {
        this.onClick(visit)
        return
      }

      this.$busy(true)
      await this.$db.setItem('clinic-doctor-visit-current', visit)
      await this.$db.setItem('isDoctorVisit', true)
      await this.$router.push({ name: 'clinic-doctor-visit-details' })
    },
    classname(statusCode) {
      switch (statusCode) {
        case 'Zarejestrowana':
          return 'registered'
        case 'Przygotowana':
          return 'prepared'
        case 'Modyfikowana':
          return 'modified'
        case 'Zakończona':
          return 'finished'
        case 'Do potwierdzenia':
          return 'to_accept'
        case 'Anulowana':
        default:
          return 'canceled'
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../../assets/scss/presets';

.item {
  box-shadow: -4px -4px 12px 2px rgba(0, 0, 0, 0.1), 4px 4px 12px 2px rgba(45, 78, 255, 0.1);
  cursor: pointer;

  &.canceled {
    border-right: 7px solid #000;
  }

  &.registered {
    border-right: 7px solid #0000ff;
  }

  &.prepared {
    border-right: 7px solid #c0c0c0;
  }

  &.modified {
    border-right: 7px solid #ffff00;
  }

  &.finished {
    border-right: 7px solid #32cd32;
  }

  &.to_accept {
    border-right: 7px solid #ff0000;
  }

  .date {
    float: right;
    margin-left: 10px;
  }
}
</style>
