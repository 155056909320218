// import store from '../store'

const Loader = {
  install(Vue, options = {}) {
    Vue.mixin({
      // beforeRouteEnter (to, from, next) {
      //   const maxTimeOffset = 1000 * 60
      //   const now = new Date().getTime()
      //   const { currentViewName, lastViewChange } = store.getters.navigation
      //   if (now - lastViewChange <= maxTimeOffset && currentViewName !== to.name && from.name === null) {
      //     next({ name: currentViewName, params: { restored: 'true' } })
      //   } else {
      //     next()
      //   }
      // },
    })
  }
}

export default Loader.install
