<template>
  <div class="panel">
    <div class="title">Dane osobowe pacjenta</div>
    <div class="mt-2 pt-2 pb-1">
      <p class="mb-0 mb-1"><b>Imię i nazwisko:</b> {{ getFullName() }}</p>
      <p class="mb-0 mb-1"><b>Wiek:</b> {{ getAge() }}</p>
      <p class="mt-0 mb-0" v-if="patientData.phoneNumber">
        <b>Numer kontaktowy:</b> {{ patientData.phoneNumber }}
      </p>
    </div>
  </div>
</template>

<script>
import { extractAge } from '@/utils/extractAge'
import { formatAge } from '@/utils/formatAge'
import { calculateAge } from '@/utils/calculateAge'

export class PatientDataType {
  constructor({
 first_name, last_name, social_security_number, age, phoneNumber, patient_id
}) {
    this.first_name = first_name
    this.last_name = last_name
    this.social_security_number = social_security_number
    this.age = age
    this.phoneNumber = phoneNumber
    this.patient_id = patient_id
  }
}
export default {
  name: 'PatientDataView',
  props: {
    patientData: {
      type: PatientDataType,
      default: null
    }
  },
  methods: {
    getFullName() {
      return `${this.patientData.first_name} ${this.patientData.last_name}`
    },
    getAge() {
      if (this.patientData.age) {
        return formatAge(extractAge(this.patientData.age))
      }
      if (this.patientData.social_security_number) {
        return formatAge(calculateAge(this.patientData.social_security_number))
      }

      return '--'
    }
  }
}
</script>

<style lang="sass" scoped></style>
