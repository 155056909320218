<template>
  <div class="page-container">
    <div v-if="isLoadingDoctorInfo" class="p-2 col-12 spinner-container">
      <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
    </div>
    <div v-else>
      <div class="panel flex-panel mb-2">
        <div>
          <div>
            <b>Lokalizacja: </b>
            {{ selectedLocalization.name }} {{ selectedLocalization.address }}
            {{ selectedLocalization.postalCode }} {{ selectedLocalization.city }}
          </div>
          <div>
            <b>Wybrany lekarz:</b> {{ doctorInfo.title || '' }} {{ doctorInfo.first_name }}
            {{ doctorInfo.last_name }}
          </div>
          <div><b>Wybrany dzień:</b> {{ selectedDate.date }}</div>
        </div>
      </div>
      <div v-if="isBusy" class="p-2 col-12 spinner-container">
        <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
      </div>
      <div v-else-if="visitsForDoctor.length === 0" class="p-2 col-12">Brak wizyt w tym dniu.</div>
      <div v-else class="scroll-container">
        <VisitItem
          :visit-info="visit"
          :with-patient-info="true"
          v-bind:key="visit.registration_id + visit.visit_time"
          v-for="visit in visitsForDoctor"
        />
      </div>
    </div>
  </div>
</template>
<script>
import VisitItem from '@/views/Clinic/components/VisitItem.vue'
import isBefore from 'date-fns/isBefore'

export default {
  name: 'ClinicDoctorVisits',
  components: { VisitItem },
  data() {
    return {
      isLoadingDoctorInfo: true,
      isBusy: true,
      selectedLocalization: { undefined },
      doctorInfo: undefined,
      selectedDate: undefined,
      visitsForDoctor: []
    }
  },
  async mounted() {
    const vm = this

    const data = await vm.$db.getItem('clinic-doctor-visits-info')
    vm.selectedLocalization = data.selectedLocalization
    vm.doctorInfo = data.selectedDoctor
    vm.selectedDate = data.selectedDate

    vm.isLoadingDoctorInfo = false

    await vm.fetchVisitsForDoctor()

    vm.isBusy = false
  },
  methods: {
    async fetchVisitsForDoctor() {
      const vm = this

      const padTime = (time) => {
        const [hours, minutes] = time.split(':')
        return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`
      }

      const response = await vm.$http.post('e-zacma/doctor-visits-for-day', {
        date: vm.selectedDate.date,
        api_key: vm.selectedLocalization.apiKey,
        doctor_id: vm.doctorInfo.id
      })

      vm.visitsForDoctor = response.data.sort((v1, v2) => {
        const v1Date = new Date(`${v1.visit_date} ${padTime(v1.visit_time)}`)
        const v2Date = new Date(`${v2.visit_date} ${padTime(v2.visit_time)}`)
        return isBefore(v1Date, v2Date) ? -1 : 1
      })
    }
  }
}
</script>
<style scoped lang="scss">
.flex-panel {
  display: flex;

  & > div {
    flex-grow: 1;
  }
}

.spinner-container {
  .spinner {
    display: block;
    margin: 0 auto;
  }
}

.scroll-container {
  animation-duration: 0.2s;
  z-index: -1;
  overflow-y: auto;
  margin: 0 -8px;
  height: calc(100vh - (76px + var(--safe-area-inset-top) * 0.65) - 115px - 0.5rem);
  padding: 0 8px 10px;

  > div:first-child {
    margin-top: 10px;
  }
}
</style>
