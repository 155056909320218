<template>
  <div class="page-container">
    <div class="panel">
      <div class="title">Zlecenia do konsultacji</div>
      <div class="description">
        Gdy pacjent zostanie umówiony na zabieg operacyjny, niżej pojawi się wizyta kwalifikacyjna.
        Zapoznaj się z dołączoną kartą z kwalifikacji. Zgłoś uwagi do przekazanej dokumentacji
        on-line lub skontaktuj się z kliniką. <br /><br />
        Blokada zgłaszania uwag przez system następuje na 24 godziny przed planowanym rozpoczęciem
        zabiegu.
      </div>

      <div v-if="busy" class="d-flex justify-content-center p-4">
        <b-spinner style="width: 3rem; height: 3rem" type="grow"></b-spinner>
      </div>
      <div
        @click="openVisit(item.linked_IDRejestracja)"
        class="item panel lite-shadow orange"
        v-bind:key="item.data"
        v-for="item in visits"
      >
        <div class="visit-type"><b>Powód wizyty:</b> Kwalifikacja do {{ item.visitType }}</div>
        <div class="date"><b>Data operacji:</b> {{ item.date }} ({{ daysFrom(item.date) }})</div>
        <div class="surgeon"><b>Chirurg:</b> {{ item.surgeon }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'VisitsToReview',
  created() {
    const vm = this
    vm.fetchVisits()
  },
  data() {
    return {
      busy: true,
      visits: []
    }
  },
  methods: {
    async openVisit(IDRejestracja) {
      const vm = this
      await vm.$db.setItem('currently-viewed-visit', IDRejestracja)
      vm.$router.push({ name: 'visit-detailed-view', params: { previous: 'visits-to-review' } })
    },
    fetchVisits() {
      const vm = this
      vm.$http
        .post('e-zacma/surgery/get-to-review')
        .then((response) => {
          vm.visits = response.data
        })
        .catch(() => {
          vm.$error()
        })
        .finally(() => {
          vm.busy = false
        })
    },
    daysFrom(date) {
      return moment(date).fromNow().toLocaleLowerCase()
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/presets';

.item {
  box-shadow: -4px -4px 12px 2px rgba(0, 0, 0, 0.1), 4px 4px 12px 2px rgba(45, 78, 255, 0.1);

  &.black {
    border-right: 7px solid $mgrey;
  }

  &.blue {
    border-right: 7px solid $mblue;
  }

  &.red {
    border-right: 7px solid $mred;
  }

  &.orange {
    border-right: 7px solid $myellow;
  }

  &.green {
    border-right: 7px solid $yellow;
  }

  .date {
    // float: right;
  }
}
</style>
