<template>
  <div class="panel">
    <div class="title">Wizyty i zabiegi</div>
    <div class="description">
      W celu uzyskania dodatkowych informacji na temat wizyty i załączenia zdjęcia lub skanu
      dokumentacji medycznej kliknij kafelek wizyty.
    </div>
    <div v-if="busy" class="d-flex justify-content-center p-4">
      <b-spinner style="width: 3rem; height: 3rem" type="grow"></b-spinner>
    </div>
    <div
      @click="openDetailedView(item)"
      class="item panel lite-shadow"
      :class="classname(item.status)"
      v-bind:key="item.IDRejestracja"
      v-for="item in sortedMedicalHistory"
    >
      <div class="date font-weight-bolder">{{ item.data }}</div>
      <div class="visit-type"><b>Typ:</b> {{ item.Visit_type_name }}</div>
      <div class="status"><b>Status organizacyjny:</b> {{ statusCode(item.status) }}</div>
      <div v-show="medicalStatusCode(item.medical_status) !== false" class="medical-status">
        <b>Status medyczny:</b> {{ medicalStatusCode(item.medical_status) }}
      </div>
      <div class="doctor"><b>Specjalista:</b> {{ item.doctor }}</div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'MedicalStatus',
  props: ['medicalHistory', 'busy'],
  data() {
    return {}
  },
  computed: {
    sortedMedicalHistory() {
      return _.sortBy(this.medicalHistory, (item) => item.data).reverse()
    }
  },
  methods: {
    async openDetailedView(visit) {
      await this.$db.setItem('currently-viewed-visit', visit)
      this.$router.push({ name: 'visit-detailed-view' })
    },
    statusCode(code) {
      if (code < 0) return 'Odwołana'
      if ([0, 1].indexOf(code) !== -1) return 'Zarejestrowana'
      if (code === 2) return 'W trakcie edycji'
      if (code === 3) return 'Zakończona'
      return ''
    },
    medicalStatusCode(code) {
      if (code === 2) return 'Odstąpiono od zabiegu'
      if (code === 3) return 'Przeprowadzono zabieg operacyjny'
      return false
    },
    classname(statusCode) {
      switch (statusCode) {
        case 0:
        case 1:
          return 'blue'
        case 2:
          return 'orange'
        case 3:
          return 'green'
        default:
          return 'black'
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/scss/presets';

.item {
  box-shadow: -4px -4px 12px 2px rgba(0, 0, 0, 0.1), 4px 4px 12px 2px rgba(45, 78, 255, 0.1);

  &.black {
    border-right: 7px solid $mgrey;
  }

  &.blue {
    border-right: 7px solid $mblue;
  }

  &.red {
    border-right: 7px solid $mred;
  }

  &.orange {
    border-right: 7px solid $myellow;
  }

  &.green {
    border-right: 7px solid $yellow;
  }

  .date {
    float: right;
  }
}
</style>
