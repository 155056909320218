<template>
  <div v-if="showBlackScreen" class="black-screen"></div>
  <div v-else-if="showOnlyUserStaticPicture">
    <div class="banner-container">
      <div
        v-if="tabletSettings.staticPicBackgroundStyle === 'image'"
        class="banner-background"
        :style="{
          backgroundImage: `url(${userStaticPicture})`,
          filter: `blur(${tabletSettings.staticPicBackgroundImageBlurPercentage}px)`
        }"
      ></div>
      <div
        v-else
        class="banner-background"
        :style="{ backgroundColor: tabletSettings.staticPicBackgroundColor }"
      ></div>
      <img class="banner-image" :src="userStaticPicture" alt="User static picture" />
    </div>
  </div>
  <div v-else>
    <div v-if="contentRefreshing" class="loading-container">
      <b-spinner size="20" />
    </div>
    <div v-if="currentDoctor" class="doctor-info-container">
      <div
        v-if="nextDoctorInSchedule"
        class="next-doctor-bar"
        :style="{
          color: tabletSettings.scheduleUpcomingAppointmentsInfoTextColor,
          backgroundColor: tabletSettings.scheduleUpcomingAppointmentsInfoBackgroundColor
        }"
      >
        {{ tabletSettings.scheduleUpcomingAppointmentsInfoText }} {{ nextDoctorInSchedule.title }}
        {{ nextDoctorInSchedule.first_name }} {{ nextDoctorInSchedule.last_name }} od
        {{ nextDoctorInSchedule.startTime }} do
        {{ nextDoctorInSchedule.endTime }}
      </div>
      <div class="banner-container">
        <div
          v-if="tabletSettings.scheduleBackgroundStyle === 'image'"
          class="banner-background"
          :style="{
            backgroundImage: `url(${currentDoctor.pictureUrl || defaultPictureUrl})`,
            filter: `blur(${tabletSettings.scheduleBackgroundImageBlurPercentage}px)`
          }"
        ></div>
        <div
          v-else
          class="banner-background"
          :style="{ backgroundColor: tabletSettings.scheduleBackgroundColor }"
        ></div>
        <div v-if="!currentDoctor.pictureUrl" class="doctor-info">
          <img class="doctor-photo" :src="defaultPictureUrl" alt="Doctor's photo" />
          <div class="doctor-details">
            <div class="doctor-name">
              {{ currentDoctor.title }} {{ currentDoctor.first_name }} {{ currentDoctor.last_name }}
            </div>
            <div class="doctor-timing">
              {{ currentDoctor.startTime }} - {{ currentDoctor.endTime }}
            </div>
          </div>
        </div>
        <img
          v-else
          class="banner-image"
          :src="currentDoctor.pictureUrl"
          alt="Current doctor image"
        />
      </div>
    </div>
    <div v-else>
      <div
        v-if="nextDoctorInAd"
        class="next-doctor-bar"
        :style="{
          color: tabletSettings.adUpcomingAppointmentsInfoTextColor,
          backgroundColor: tabletSettings.adUpcomingAppointmentsInfoBackgroundColor
        }"
      >
        {{ tabletSettings.adUpcomingAppointmentsInfoText }} {{ nextDoctorInAd.title }}
        {{ nextDoctorInAd.first_name }} {{ nextDoctorInAd.last_name }} od
        {{ nextDoctorInAd.startTime }} do
        {{ nextDoctorInAd.endTime }}
      </div>
      <div class="banner-container" v-if="currentBanner">
        <div
          v-if="tabletSettings.adBackgroundStyle === 'image'"
          class="banner-background"
          :style="{
            backgroundImage: `url(${currentBanner.image_url})`,
            filter: `blur(${tabletSettings.adBackgroundImageBlurPercentage}px)`
          }"
        ></div>
        <div
          v-else
          class="banner-background"
          :style="{ backgroundColor: tabletSettings.adBackgroundColor }"
        ></div>
        <img class="banner-image" :src="currentBanner.image_url" alt="Banner image" />
      </div>
    </div>
  </div>
</template>

<script>
import Pusher from 'pusher-js'
import isBefore from 'date-fns/isBefore'
import { addDays, isAfter } from 'date-fns'

export default {
  name: 'clinic-office',
  data() {
    return {
      currentOfficeUser: {},
      pusher: null,
      schedule: [],
      docPictures: [],
      banners: [],
      userStaticPicture: '',
      showOnlyUserStaticPicture: false,
      defaultPictureUrl: '',
      tabletSettings: {
        updateTime: '06:00',
        hideContentForNight: false,
        hideContentForNightStart: '22:00',
        hideContentForNightEnd: '06:00',
        staticPicBackgroundStyle: 'color',
        staticPicBackgroundColor: '#000000',
        staticPicBackgroundImageBlurPercentage: 5,
        adBackgroundStyle: 'color',
        adBackgroundColor: '#000000',
        adBackgroundImageBlurPercentage: 5,
        showAdUpcomingAppointmentsInfo: false,
        adShowUpcomingLeadTime: 15,
        adUpcomingAppointmentsInfoText: 'Za niedługo w gabinecie przyjmować będzie:',
        adUpcomingAppointmentsInfoTextColor: '#000000',
        adUpcomingAppointmentsInfoBackgroundColor: '#ffffff',
        scheduleBackgroundStyle: 'color',
        scheduleBackgroundColor: '#fff',
        scheduleBackgroundImageBlurPercentage: 5,
        showScheduleUpcomingAppointmentsInfo: false,
        scheduleShowUpcomingLeadTime: 15,
        scheduleUpcomingAppointmentsInfoText: 'Za niedługo w gabinecie przyjmować będzie:',
        scheduleUpcomingAppointmentsInfoTextColor: '#000000',
        scheduleUpcomingAppointmentsInfoBackgroundColor: '#ffffff'
      },
      currentDoctor: null,
      currentBanner: null,
      bannerIndex: 0,
      bannerTimeout: null,
      firstLoadDone: false,
      contentRefreshing: false,
      nextDoctorInAd: null,
      nextDoctorInSchedule: null,
      showBlackScreen: false,
      hasInternetConnection: navigator.onLine
    }
  },
  created() {
    // Pusher.logToConsole = true
    this.initializePusher()
    this.subscribeBanners()
    this.subscribeStaffPictures()
    this.subscribeSchedule()
    this.subscribeSettings()
    this.subscribeClinicOfficePic()
  },
  async mounted() {
    this.$busy(true)
    const account = await this.$db.getItem('account')
    const user = await this.$db.getItem('user')
    this.currentOfficeUser = {
      ...account,
      ...user
    }
    await this.reloadData()
    this.disableZoomable()
    document.querySelector('meta[name="theme-color"]').setAttribute('content', '#000')
    this.firstLoadDone = true
    setInterval(this.updateCurrentDisplay, 1000)
    setInterval(this.checkReloadTime, 60000)
    setInterval(this.checkInternetConnection, 1000)

    this.$busy(false)
  },
  methods: {
    checkInternetConnection() {
      if (navigator.onLine) {
        if (!this.hasInternetConnection) {
          console.log('Internet connection restored')
          this.hasInternetConnection = true
          this.reloadData()
        }
      } else if (this.hasInternetConnection) {
        console.log('Internet connection lost')
        this.hasInternetConnection = false
      }
    },
    initializePusher() {
      this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
        encrypted: true
      })
    },
    disconnectPusher() {
      if (this.pusher) {
        this.pusher.disconnect()
      }
    },
    connectPusher() {
      if (this.pusher) {
        this.pusher.connect()
      }
    },
    async checkReloadTime() {
      const { updateTime } = this.tabletSettings
      const [hours, minutes] = updateTime.split(':')
      const hoursStr = hours.startsWith('0') ? hours.slice(1) : hours
      const minutesStr = minutes.startsWith('0') ? minutes.slice(1) : minutes
      const hoursInt = parseInt(hoursStr, 10)
      const minutesInt = parseInt(minutesStr, 10)
      const now = new Date()
      if (now.getHours() === hoursInt && now.getMinutes() === minutesInt) {
        this.$busy(true)
        await this.reloadData()
        this.$busy(false)
      }
    },
    async reloadData() {
      const promises = [
        this.fetchClinicOfficeSchedule(),
        this.fetchClinicOfficeDoctorsPictures(),
        this.fetchClinicOfficeBanners(),
        this.fetchTabletSettings(),
        this.fetchClinicOfficeStaticPicture()
      ]
      await Promise.all(promises)
      this.updateCurrentDisplay()
    },
    subscribeBanners() {
      const channel = this.pusher.subscribe('banners')
      channel.bind('banners_modified', (data) => {
        const scopeId = data.scope_id
        if (this.currentOfficeUser.organization_id === scopeId) {
          this.fetchClinicOfficeBanners().then(() => {
            this.bannerIndex = 0
            this.clearBannerTimeout()
            this.currentBanner = null
            this.updateCurrentDisplay()
          })
        }
      })
    },
    subscribeSchedule() {
      const channel = this.pusher.subscribe('schedule')
      channel.bind('schedule_updated', (data) => {
        const { office_id, scope_id } = data

        const isForCurrentUser = this.currentOfficeUser.unison_id === office_id
        const isForCurrentOrganization = this.currentOfficeUser.organization_id === scope_id

        if (isForCurrentUser && isForCurrentOrganization) {
          this.fetchClinicOfficeSchedule().then(() => {
            this.updateCurrentDisplay()
          })
        }
      })
    },
    subscribeSettings() {
      const channel = this.pusher.subscribe('settings')
      channel.bind('settings_updated', (data) => {
        const { scope_id } = data

        const isForCurrentOrganization = this.currentOfficeUser.organization_id === scope_id

        if (isForCurrentOrganization) {
          this.fetchTabletSettings().then(() => {
            this.updateCurrentDisplay()
          })
        }
      })
    },
    subscribeClinicOfficePic() {
      const channel = this.pusher.subscribe('account')
      channel.bind('user-updated', (data) => {
        const { scope_id, userId } = data

        const isForCurrentUser = this.currentOfficeUser.user_id === userId
        const isForCurrentOrganization = this.currentOfficeUser.organization_id === scope_id

        if (isForCurrentOrganization && isForCurrentUser) {
          this.fetchClinicOfficeStaticPicture().then(() => {
            this.updateCurrentDisplay()
          })
        }
      })
    },
    subscribeStaffPictures() {
      const channel = this.pusher.subscribe('staff')
      channel.bind('staff_updated', (data) => {
        const scopeId = data.scope_id
        if (this.currentOfficeUser.organization_id === scopeId) {
          this.fetchClinicOfficeDoctorsPictures()
          this.updateCurrentDisplay()
        }
      })
    },
    async fetchTabletSettings() {
      if (this.firstLoadDone) {
        this.contentRefreshing = true
      }

      try {
        const response = await this.$http.post('e-zacma/tablets/get-settings-for-office')
        this.tabletSettings = JSON.parse(response.data.tablet_settings)
      } catch (error) {
        console.error('Error fetching tablet settings:', error)
      } finally {
        this.contentRefreshing = false
      }
    },
    async fetchClinicOfficeStaticPicture() {
      if (this.firstLoadDone) {
        this.contentRefreshing = true
      }

      try {
        const response = await this.$http.post('e-zacma/tablets/get-clinic-office-static-picture')
        this.userStaticPicture = response.data.userPicture.picture_url || ''
      } catch (error) {
        console.error('Error fetching tablet settings:', error)
      } finally {
        this.contentRefreshing = false
      }
    },
    async fetchClinicOfficeSchedule() {
      if (this.firstLoadDone) {
        this.contentRefreshing = true
      }

      try {
        const response = await this.$http.post('e-zacma/tablets/get-clinic-office-schedule')
        this.schedule = response.data
      } catch (error) {
        console.error('Error fetching clinic office schedule:', error)
      } finally {
        this.contentRefreshing = false
      }
    },

    async fetchClinicOfficeBanners() {
      if (this.firstLoadDone) {
        this.contentRefreshing = true
      }

      try {
        const response = await this.$http.post('e-zacma/tablets/get-clinic-office-banners')
        this.banners = response.data

        this.banners.forEach((banner) => {
          fetch(banner.image_url)
        })
      } catch (error) {
        console.error('Error fetching clinic office banners:', error)
      } finally {
        this.contentRefreshing = false
      }
    },
    async fetchClinicOfficeDoctorsPictures() {
      if (this.firstLoadDone) {
        this.contentRefreshing = true
      }

      try {
        const response = await this.$http.post('e-zacma/tablets/get-clinic-office-doctors-pictures')
        this.docPictures = response.data.docPictures
        this.defaultPictureUrl = response.data.defaultPictureUrl

        this.docPictures.forEach((picture) => {
          fetch(picture.picture_url)
        })

        if (this.defaultPictureUrl) {
          fetch(this.defaultPictureUrl)
        }
      } catch (error) {
        console.error('Error fetching clinic office doctors pictures:', error)
      } finally {
        this.contentRefreshing = false
      }
    },
    findNextDoctorScheduleForAd(todaySchedule, currentTime, currentDate, now) {
      const { adShowUpcomingLeadTime } = this.tabletSettings
      const nextDoctorSchedule = todaySchedule.schedule.find((s) => s.startTime > currentTime)
      if (nextDoctorSchedule) {
        const nextDoctorStartTime = new Date(`${currentDate}T${nextDoctorSchedule.startTime}`)
        const timeDifference = (nextDoctorStartTime - now) / 1000 / 60 // różnica w minutach

        if (timeDifference <= adShowUpcomingLeadTime) {
          const nextDoctor = todaySchedule.doctors.find(
            (d) => d.id === nextDoctorSchedule.doctor_id
          )
          const nextDoctorPicture = this.docPictures.find((p) => p.user_id === nextDoctor.id)
          this.nextDoctorInAd = {
            ...nextDoctor,
            startTime: nextDoctorSchedule.startTime,
            endTime: nextDoctorSchedule.endTime,
            pictureUrl: nextDoctorPicture ? nextDoctorPicture.picture_url : null
          }
        } else {
          this.nextDoctorInAd = null
        }
      } else {
        this.nextDoctorInAd = null
      }
    },
    findNextDoctorScheduleDuringSchedule(todaySchedule, currentTime, currentDate, now) {
      const { scheduleShowUpcomingLeadTime } = this.tabletSettings
      const nextDoctorSchedule = todaySchedule.schedule.find((s) => s.startTime > currentTime)
      if (nextDoctorSchedule) {
        const nextDoctorStartTime = new Date(`${currentDate}T${nextDoctorSchedule.startTime}`)
        const timeDifference = (nextDoctorStartTime - now) / 1000 / 60 // różnica w minutach

        if (timeDifference <= scheduleShowUpcomingLeadTime) {
          const nextDoctor = todaySchedule.doctors.find(
            (d) => d.id === nextDoctorSchedule.doctor_id
          )
          const nextDoctorPicture = this.docPictures.find((p) => p.user_id === nextDoctor.id)
          this.nextDoctorInSchedule = {
            ...nextDoctor,
            startTime: nextDoctorSchedule.startTime,
            endTime: nextDoctorSchedule.endTime,
            pictureUrl: nextDoctorPicture ? nextDoctorPicture.picture_url : null
          }
        } else {
          this.nextDoctorInSchedule = null
        }
      } else {
        this.nextDoctorInSchedule = null
      }
    },
    updateCurrentDisplay() {
      const {
        hideContentForNight,
        hideContentForNightStart,
        hideContentForNightEnd,
        showAdUpcomingAppointmentsInfo,
        showScheduleUpcomingAppointmentsInfo
      } = this.tabletSettings
      const now = new Date()
      const currentDate = now.toISOString().split('T')[0]
      const currentTime = now.toTimeString().split(' ')[0]

      if (hideContentForNight) {
        const blackScreenStart = new Date(`${currentDate}T${hideContentForNightStart}`)
        const blackScreenEnd = addDays(new Date(`${currentDate}T${hideContentForNightEnd}`), 1)

        if (isAfter(now, blackScreenStart) && isBefore(now, blackScreenEnd)) {
          this.showBlackScreen = true
        } else {
          this.showBlackScreen = false
        }

        if (this.showBlackScreen) {
          this.currentDoctor = null
          this.nextDoctorInAd = null
          this.nextDoctorInSchedule = null
          return
        }
      } else {
        this.showBlackScreen = false
      }

      if (
        this.userStaticPicture !== ''
        && this.userStaticPicture !== null
        && this.userStaticPicture !== undefined
      ) {
        this.showOnlyUserStaticPicture = true
        return
      }
      this.showOnlyUserStaticPicture = false

      const todaySchedule = this.schedule.find((s) => s.date === currentDate)
      if (todaySchedule) {
        const currentDoctorSchedule = todaySchedule.schedule.find(
          (s) => s.startTime <= currentTime && s.endTime >= currentTime
        )

        if (showAdUpcomingAppointmentsInfo) {
          this.findNextDoctorScheduleForAd(todaySchedule, currentTime, currentDate, now)
        } else {
          this.nextDoctorInAd = null
        }

        if (currentDoctorSchedule) {
          if (showScheduleUpcomingAppointmentsInfo) {
            this.findNextDoctorScheduleDuringSchedule(todaySchedule, currentTime, currentDate, now)
          } else {
            this.nextDoctorInSchedule = null
          }

          const doctor = todaySchedule.doctors.find((d) => d.id === currentDoctorSchedule.doctor_id)
          const doctorPicture = this.docPictures.find((p) => p.user_id === doctor.id)
          this.currentDoctor = {
            ...doctor,
            startTime: currentDoctorSchedule.startTime,
            endTime: currentDoctorSchedule.endTime,
            pictureUrl: doctorPicture ? doctorPicture.picture_url : null
          }
          this.clearBannerTimeout()
          this.currentBanner = null
          return
        }
      }

      this.currentDoctor = null
      this.updateCurrentBanner()
    },
    updateCurrentBanner() {
      if (this.banners.length > 0) {
        this.currentBanner = this.banners[this.bannerIndex]
        if (!this.bannerTimeout) {
          this.clearBannerTimeout()
          this.bannerTimeout = setTimeout(() => {
            this.bannerIndex = (this.bannerIndex + 1) % this.banners.length
            this.clearBannerTimeout()
            this.updateCurrentBanner()
          }, this.currentBanner.duration * 1000)
        }
      } else {
        this.currentBanner = null
      }
    },
    clearBannerTimeout() {
      if (this.bannerTimeout) {
        clearTimeout(this.bannerTimeout)
        this.bannerTimeout = null
      }
    },
    changeViewportContent(content) {
      const viewport = document.querySelector('meta[name=viewport]')
      viewport.setAttribute('content', content)
    },
    disableZoomable() {
      document.addEventListener('gesturestart', (e) => {
        e.preventDefault()
      })

      document.addEventListener('gesturechange', (e) => {
        e.preventDefault()
      })

      document.addEventListener('gestureend', (e) => {
        e.preventDefault()
      })
      this.changeViewportContent(
        'initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, width=device-width'
      )
    }
  },
  beforeDestroy() {
    this.disconnectPusher()
    this.clearBannerTimeout()
  }
}
</script>

<style scoped>
body {
  background-color: #000000; /* Kolor tła - pasek nawigacyjny na dole dziedziczy ten kolor */
}

::-webkit-scrollbar {
  background-color: #000000; /* Tło paska przewijania w WebKit */
}

/* Specyficzne dla Androida */
::-webkit-scrollbar-thumb {
  background-color: #000000; /* Kolor suwaka na pasku przewijania */
}

.doctor-info-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.doctor-info {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.doctor-photo {
  width: 65vmin;
  height: 65vmin;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.doctor-details {
  flex: 1;
  font-size: 3rem;
}

.doctor-name {
  font-weight: bold;
  margin-bottom: 10px;
}

.doctor-timing {
  font-style: italic;
  color: #555;
}

.banner-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  z-index: 1;
}

.banner-image {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: block;
  z-index: 2;
}

.loading-container {
  position: fixed;
  bottom: 5px;
  right: 5px;

  z-index: 10;
}

.next-doctor-bar {
  position: fixed;
  top: 0;
  right: 0;
  width: auto;
  padding: 5px 20px 5px 40px;
  text-align: center;
  font-weight: bold;
  z-index: 10;
  border-radius: 0 0 0 50px;
}

.black-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 100; /* najwyższy priorytet, aby przykryć wszystko inne */
}
</style>
