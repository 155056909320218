<template>
  <div class="page-container">
    <div v-if="!selectedLocalization" class="panel">
      <div class="title">Wybierz lokalizację</div>
      <button
        @click="chooseLocalization(item)"
        class="button pink btn mt-3"
        v-bind:key="item.apiKey"
        v-for="item in localizations"
      >
        {{ item.name }} {{ item.address }} {{ item.postalCode }} {{ item.city }}
      </button>
    </div>
    <template v-else>
      <div class="panel flex-panel">
        <div>
          <div class="title">
            {{ localizations.length > 1 ? 'Wybrana lokalizacja' : 'Lokalizacja' }}
          </div>
          <div>
            {{ selectedLocalization.name }} {{ selectedLocalization.address }}
            {{ selectedLocalization.postalCode }} {{ selectedLocalization.city }}
          </div>
        </div>
        <div v-if="localizations.length > 1">
          <button class="button pink btn mt-3" @click="clearSelectedLocalization()">Zmień</button>
        </div>
      </div>
      <div class="panel">
        <div class="title">Wybierz powód wizyty</div>
        <button
          @click="choose(item)"
          class="button pink btn mt-3"
          v-bind:key="item.group_type"
          v-for="item in allowed"
        >
          {{ item.group_name }}
        </button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'VisitType',
  data() {
    return {
      selectedLocalization: undefined,
      localizations: [],
      allowed: [],
      scope: {}
    }
  },
  async mounted() {
    const vm = this

    vm.$busy(true)

    vm.localizations = await vm.$db.getItem('locations')
    vm.$logEvent('visit_types_page', { localizations: vm.localizations })
    if (vm.localizations.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      vm.selectedLocalization = vm.localizations[0]
      await vm.fetchAllowedVisitTypes()
    }

    await vm.restoreSelectedLocalization()

    vm.$busy(false)
  },
  methods: {
    async restoreSelectedLocalization() {
      const vm = this
      const selectedLocalization = await vm.$db.getItem('previous_selected_localization')
      if (selectedLocalization) {
        const localizations = await vm.$db.getItem('locations')

        const foundLocalization = localizations.find(
          (el) => el.apiKey === selectedLocalization.apiKey
        )
        if (foundLocalization) {
          await this.chooseLocalization(foundLocalization)
        }
      }
    },
    async clearSelectedLocalization() {
      const vm = this
      vm.selectedLocalization = undefined
      await vm.$db.removeItem('previous_selected_localization')
      vm.allowed = []
    },
    async chooseLocalization(item) {
      const vm = this
      vm.selectedLocalization = item
      await vm.$db.setItem('previous_selected_localization', item)
      await vm.fetchAllowedVisitTypes()
    },
    async fetchAllowedVisitTypes() {
      const vm = this
      const visitGroups = await vm.$db.getItem('visitGroups')
      const filterBySelectedLocalization = (el) => {
        const api_keys = JSON.parse(el.api_keys)
        return api_keys.includes(vm.selectedLocalization.apiKey)
      }

      vm.allowed = visitGroups.filter(filterBySelectedLocalization)
    },
    async choose(item) {
      const vm = this
      vm.$busy(true)
      const requestSettings = []
      const { apiKey } = vm.selectedLocalization
      const data = JSON.parse(item.data)

      const qualificationIds = data.qualification

      // eslint-disable-next-line no-restricted-syntax,camelcase
      for (const visit_type of qualificationIds) {
        requestSettings.push({
          api_key: apiKey,
          visit_type: visit_type.IDAnkietaPytanie
        })
      }

      await vm.$db.setItem('calendar_request_settings', requestSettings)
      await vm.$db.setItem('visit_type', item.group_name)

      vm.$logEvent('visit_type_select', { visit_type: item.group_name })
      await vm.$router.push({ name: 'calendar' })
      vm.$busy(false)
    }
  }
}
</script>

<style scoped lang="scss">
button.pink.btn.button {
  color: #fff;
}
.flex-panel {
  display: flex;
  gap: 10px;

  & > div:first-child {
    flex: 1;
  }
}
</style>
