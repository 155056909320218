<template>
  <div id="app" :class="appClasses">
    <div
      class="overlay"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      v-if="isOverlayVisible"
    >
      <div v-if="isClinicOffice && isOverlayVisible">
        <button
          v-for="(button, index) in buttons"
          :key="index"
          :class="button.class"
          class="hidden-button"
        ></button>
      </div>
    </div>
    <div id="background"></div>
    <navbar @lock="lockedClick" :isClinicOffice="isClinicOffice"></navbar>
    <main>
      <transition :name="transitionName">
        <router-view />
      </transition>
    </main>
  </div>
</template>
<script>
import toast from 'izitoast'
import Navbar from './components/Navbar.vue'
import 'izitoast/dist/css/iziToast.min.css'

const DEFAULT_TRANSITION = 'fade'

export default {
  created() {
    const vm = this
    document.addEventListener('swUpdated', vm.appUpdateAvailable, { once: true })
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (vm.refreshing) return
        vm.refreshing = true
        window.location.reload(true)
      })
    }

    vm.updateUserInfo()
    vm.setupActivityMonitor()
  },
  mounted() {
    // this.$nextTick(this.destroyPreloader)
  },
  computed: {
    appClasses() {
      return {
        [this.$route.name]: true,
        'clinic-office': this.isClinicOffice
      }
    },
    isOverlayVisible() {
      return this.isLocked
    }
  },
  data() {
    return {
      transitionName: DEFAULT_TRANSITION,
      refreshing: false,
      registration: null,
      updateExists: false,
      buttons: [
        { class: 'button-top-right', pressed: false },
        { class: 'button-bottom-right', pressed: false },
        { class: 'button-center', pressed: false }
      ],
      isLocked: false,
      isClinicOffice: false,
      centerClickCount: 0,
      centerClickLimit: 3,
      unlockTimeLimit: 1000, // 1 seconds to click 3 times
      unlockTimer: null
    }
  },
  watch: {
    $route(to, from) {
      if (from.meta.depth !== undefined && to.params.restored === undefined) {
        this.transitionName = to.meta.depth > from.meta.depth ? 'slide-left' : 'slide-right'
        this.$store.commit('viewChanged', to.name)
      }
    },

    updateExists() {
      toast.show({
        title: 'Aplikacja zostanie za chwilę zaktualizowana do najnowszej wersji',
        titleSize: 18,
        titleLineHeight: 30,
        close: true,
        icon: 'fas fa-info-circle',
        position: 'bottomCenter',
        displayMode: 1,
        theme: 'dark',
        backgroundColor: '#50c9c3',
        timeout: 3000
      })

      setTimeout(() => {
        this.update()
      }, 3000)
    }
  },
  methods: {
    handleTouchStart(event) {
      const targetClass = event.target.className
      this.handlePressStart(targetClass)
    },
    handleTouchEnd(event) {
      const targetClass = event.target.className
      this.handlePressEnd(targetClass)
    },
    handlePressStart(targetClass) {
      if (targetClass.includes('button-top-right')) {
        this.buttons[0].pressed = true
      } else if (targetClass.includes('button-bottom-right')) {
        this.buttons[1].pressed = true
      } else if (targetClass.includes('button-center')) {
        this.buttons[2].pressed = true
        if (this.buttons[0].pressed && this.buttons[1].pressed) {
          this.centerClickCount += 1
          if (this.centerClickCount === 1) {
            this.startUnlockTimer()
          }
        }
      }
      this.checkUnlock()
    },
    handlePressEnd(targetClass) {
      if (targetClass.includes('button-top-right')) {
        this.buttons[0].pressed = false
        this.centerClickCount = 0
      } else if (targetClass.includes('button-bottom-right')) {
        this.buttons[1].pressed = false
        this.centerClickCount = 0
      } else if (targetClass.includes('button-center')) {
        this.buttons[2].pressed = false
      }
    },
    startUnlockTimer() {
      this.unlockTimer = setTimeout(() => {
        this.centerClickCount = 0
      }, this.unlockTimeLimit)
    },
    checkUnlock() {
      if (
        this.buttons[0].pressed
        && this.buttons[1].pressed &&
        this.centerClickCount >= this.centerClickLimit
      ) {
        this.isLocked = false
        this.centerClickCount = 0
        this.$toast.success({ title: 'Ekran Odblokowany!', timeout: 1000 })
        clearTimeout(this.unlockTimer)
      }
    },
    lockedClick() {
      this.isLocked = true
    },
    setupActivityMonitor() {
      const vm = this
      const recordActivity = vm.debounce(vm.recordActivity, 10000, true)
      document.addEventListener('touchstart', recordActivity, false)
      document.addEventListener('touchmove', recordActivity, false)
      document.addEventListener('touchend', recordActivity, false)
      document.addEventListener('touchcancel', recordActivity, false)
      document.addEventListener('click', recordActivity, false)
    },
    recordActivity() {
      const vm = this
      vm.$http.post('e-zacma/statistics/record-activity').catch((err) => {})
    },
    debounce(func, wait, immediate) {
      let timeout
      return function (...args) {
        const context = this
        const later = function () {
          timeout = null
          if (!immediate) func.apply(context, args)
        }
        const callNow = immediate && !timeout
        clearTimeout(timeout)
        timeout = setTimeout(later, wait)
        if (callNow) func.apply(context, args)
      }
    },
    async updateUserInfo(force = false) {
      const vm = this
      if ((await vm.updateTime()) || force) {
        let responseData = {}
        await vm.$http
          .post('e-zacma/update-consultant-info')
          .then((response) => {
            responseData = response.data
          })
          .catch((e) => vm.$handleLaravelErrorResponse(e))
          .finally(vm.setNextUpdateTime)
        if (responseData) {
          await vm.$db.setItem('user', responseData.user)
          localStorage.setItem('user', JSON.stringify(responseData.user))
          await vm.$db.setItem('account', responseData.account)
          await vm.$db.setItem('visitGroups', responseData.visitGroups)
          await vm.$db.setItem('smsApiConfig', responseData.smsApiConfig)

          if (responseData?.user?.account_type === 'clinic_office') {
            vm.isClinicOffice = true
            return
          }
          vm.isClinicOffice = false

          const response = await vm.$http
            .post('e-zacma/get-allowed-api-keys')
            .catch((e) => vm.$handleLaravelErrorResponse(e))
            .finally(vm.setNextUpdateTime)
          const locations = response.data?.map((location) => ({
            apiKey: location.api_key,
            name: location.name,
            postalCode: location.postal_code,
            address: location.address,
            city: location.city
          }))
          await vm.$db.setItem('locations', locations)
        }
      }
    },
    async updateTime() {
      const vm = this
      return vm.$db
        .getItem('next_user_update')
        .then((time) => time < new Date().getTime())
        .catch(() => true)
    },
    setNextUpdateTime() {
      const vm = this
      vm.$db.setItem('next_user_update', new Date().getTime() + 1000 * 60 * 60)
    },
    appUpdateAvailable(e) {
      const vm = this
      vm.registration = e.detail
      vm.updateExists = true
    },
    update() {
      const vm = this
      vm.updateExists = false
      // console.log({
      //   reg: vm.registration,
      //   waiting: vm.registration.waiting
      // })
      if (!vm.registration || !vm.registration.waiting) return
      vm.registration.waiting.postMessage('skipWaiting')
    },
    showUpdateToast() {
      const vm = this
      toast.show({
        title: 'Dostępna jest aktualizacja aplikacji',
        titleSize: 18,
        titleLineHeight: 30,
        close: false,
        icon: 'fas fa-info-circle',
        position: 'bottomCenter',
        displayMode: 1,
        theme: 'dark',
        backgroundColor: '#50c9c3',
        timeout: false,
        buttons: [
          [
            '<button class="btn font-weight-bold" style="width: 100% !important;">Aktualizuj</button>',
            () => vm.update(),
            true
          ]
        ]
      })
    },
    destroyPreloader() {
      const preloader = document.getElementById('pre-loader')
      if (preloader !== null) document.getElementsByTagName('body')[0].removeChild(preloader)
    }
  },
  components: {
    Navbar
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,900&sunset=latin-ext');
@import 'assets/scss/presets';

html {
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  @include main-gradient();
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

body {
  height: 100%;
  /* Iphone X support */
  @supports (height: env(safe-area-inset-top)) {
    border-top: env(safe-area-inset-top) solid
      linear-gradient(60deg, #96deda 0%, darken(#50c9c3, 5) 100%);
    min-height: calc(100% + env(safe-area-inset-top));
  }
  background-repeat: no-repeat;
  background-attachment: fixed;
  /*background: url("assets/background.png");*/
  // background-image: repeating-linear-gradient(to bottom, lighten($blue-1, 7), $blue-1);
  @include main-gradient();
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  padding-bottom: 20px;
}

#app {
  min-height: 100%;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: linear-gradient(to bottom, lighten($blue-1, 7), $blue-1);
}

* {
  outline: none !important;
  font-family: 'Open Sans', Helvetica, Arial, sans-serif !important;
}

#app {
  &.clinic-office {
    & > nav.main-navbar {
      background: transparent;
      border-bottom: none;

      & .toggler {
        > svg {
          color: transparent;
        }
      }

      & .opened-menu-items {
        background: rgb(0, 0, 0, 0.2);

        > div {
          background: rgb(0, 0, 0, 0.2);
        }

        & .danger {
          background: rgba(230, 49, 49, 0.2);
        }
      }
    }
  }

  &.home.clinic-office {
    .hidden-button {
      position: absolute;
      width: 200px;
      height: 200px;
      opacity: 0;
      z-index: 9999;
      border: none;
      pointer-events: auto;
    }

    .hidden-button:active {
      pointer-events: auto;
    }

    .button-top-right {
      top: 0;
      right: 0;
    }

    .button-bottom-right {
      bottom: 0;
      right: 0;
    }

    .button-center {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    & > .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0);
      z-index: 9998; /* Ensure overlay is always on top */
      pointer-events: all;
      touch-action: none;
    }

    > main {
      padding-top: 0;
    }
  }
}

main {
  width: 100%;
  min-height: 100%;
  max-width: 100%;
  display: grid;
  grid-template: 'main';
  flex: 1;
  background-repeat: no-repeat;
  background-attachment: fixed;
  box-sizing: border-box;
  // background-image: repeating-linear-gradient(to bottom, lighten($blue-1, 7), $blue-1);
  @include main-gradient();
  position: relative;
  z-index: 0;
  padding-top: 55px;
  padding-bottom: 0;

  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-top: constant(safe-area-inset-top)) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    padding-bottom: 0;
    padding-top: calc(55px + var(--safe-area-inset-top) * 0.65);
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-top: env(safe-area-inset-top)) {
    --safe-area-inset-top: env(safe-area-inset-top);
    padding-bottom: 0;
    padding-top: calc(55px + var(--safe-area-inset-top) * 0.65);
  }
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  background-repeat: no-repeat;
  background-attachment: fixed;
  // background-image: repeating-linear-gradient(to bottom, lighten($blue-1, 7), $blue-1);
  position: relative;
}

main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
  height: calc(100% - 57px);
}

/* Toasts */

.iziToast.iziToast-animateInside .iziToast-icon {
  opacity: 1;
}

.alert {
  border-radius: $border-radius;
}

.ajs-modal {
  padding-top: 30vh !important;
}

.iziToast-body {
  // Browsers which partially support CSS Environment variables (iOS 11.0-11.2).
  @supports (padding-bottom: constant(safe-area-inset-top)) {
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
    padding-bottom: max(0, calc(var(--safe-area-inset-bottom))) !important;
  }

  // Browsers which fully support CSS Environment variables (iOS 11.2+).
  @supports (padding-bottom: env(safe-area-inset-bottom)) {
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
    padding-bottom: max(0, calc(var(--safe-area-inset-bottom))) !important;
  }
}

/* Transitions */

.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}

.page-container {
  width: calc(100vw - 16px);
  /* iPhone X ready */
  margin: 0 8px;
  /*@supports(margin: max(0px)) {*/
  /*  margin-right: max(8px, env(safe-area-inset-right));*/
  /*  margin-left: max(8px, env(safe-area-inset-left));*/
  /*  padding-bottom: env(safe-area-inset-bottom);*/
  /*}*/
}

.login {
  .navbar {
    display: none;
  }
}

.panel {
  background-color: $white;
  padding: 15px;
  margin-top: 10px;
  border-radius: $panel-border-radius;
  // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  @include box-shadow();

  .title {
    color: $black-1;
    font-size: 24px;
    font-weight: 700;
  }

  .subtitle {
    color: $grey-2;
    font-size: 19px;
    font-weight: 700;
  }

  .description {
    font-size: 15px;
    padding: 0 0 10px 0;
    color: $grey-2;
  }

  &.yellow {
    background-color: $yellow;
    color: $white;
  }

  .closest-surgery-date-text {
    font-weight: 900;
    font-size: 22px;
  }

  .closest-surgery-date {
    font-size: 18px;
  }
}

.calendar {
  header {
    .hr > div {
      border-bottom: 2px solid $black-1;
    }

    .month-changer {
      padding-top: 10px;
      font-size: 25px;
      .month {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}

.button {
  width: 100%;
  min-height: 60px;
  font-weight: bold;
  line-height: 45px;
  color: $white;
  background-color: $pink;
  // @include theme-gradient();
  border-radius: $controls-border-radius;
  font-size: 18px;
  box-sizing: border-box;
  // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  // @include box-shadow();

  &.outlined {
    background-color: $white;
    border: 3px solid $pink;
    color: $pink;
  }

  &:hover:not(.outlined) {
    color: $white;
    background-color: darken($pink, 3);
  }

  &.no-shadow {
    box-shadow: none;
    border: none;
  }

  &.height-auto {
    height: auto;
  }
}

.form-group {
  label {
    color: $black-1;
    font-weight: 600;
    padding-left: 0;
    margin-bottom: 0;
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  .form-control {
    height: 60px;
    font-size: 125%;
    border-radius: $controls-border-radius;
  }

  .form-text.text-muted {
    padding-top: 5px;
    font-size: 90%;
  }

  > label {
    font-size: 140%;
    padding-bottom: 5px;
  }
}

.calendar-container {
  .header {
    margin: 0 0 10px;

    .month {
      margin-top: -10p;
      line-height: 48px;
      font-size: 2em;
      text-align: center;
    }
    .year {
      line-height: 20px;
      font-size: 1.1em;
      text-align: center;
    }
  }

  .body {
    width: 100%;
    overflow-x: hidden;
    // height: 40px;

    .scroll-container {
      width: 100%;
      overflow-x: scroll;
      /*overflow-y: hidden;*/
      -webkit-overflow-scrolling: touch;
      /*scroll-snap-type: mandatory;*/
      scroll-snap-type: x mandatory;
      /* older spec implementation */
      scroll-snap-destination: 0% 100%;
      scroll-snap-points-x: repeat(100%);

      > div {
        padding: 0;
      }

      .block {
        width: 100%;
        scroll-snap-align: center;
      }

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    .empty {
      width: inherit;
      text-align: center;
    }

    .day-col {
      color: $black-1;
      /*flex: 20%;*/
      /*max-width: 20%;*/
      padding: 8px;

      .circle {
        width: 40px;
        height: 55px;
        // border: 2px solid $black-1;
        /*border-radius: 50%;*/
        cursor: pointer;
        /*box-shadow: 0 3px 6px rgba(0,0,0,0.12), 0 1px 1px rgba(0,0,0,0.12);*/
        transition: background-color 0.2s;

        &.active .number > div {
          background-color: lighten($pink, 2);
          color: $white;
          font-weight: bold;
        }

        &:hover,
        &.active {
          // color: $white;
          // border-color: $white;
        }

        .day {
          font-size: 13px;
          font-weight: bold;
        }

        .day,
        .number {
          text-align: center;
        }

        .number {
          font-size: 18px;
          padding-top: 5px;

          > div {
            height: 40px;
            border-radius: 50%;
            line-height: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}

.panel-day-picker {
  display: flex;
}

.day-picker {
  max-width: 100%;
  color: #262930;
  min-height: 80px;
  cursor: pointer;
  border-right: 8px solid lighten($pink, 0);

  .time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    line-height: 55px;
    text-align: center;
  }

  .clinic,
  .doctor,
  .price {
    line-height: 27px;
    /*padding-left: 0;*/
    font-size: 15px;
  }

  .icon {
    margin-right: -10px;
    font-size: 30px;
    padding-top: 10px;

    @media screen and (max-width: 480px) {
      margin-top: -55px;
    }
  }
}

.home .prev .fas {
  display: none;
}

.pagination {
  .page-item {
    .page-link {
      color: $pink;
    }

    &.active .page-link {
      background-color: $pink;
      border-color: $pink;
      color: $white;
    }
  }
}
</style>
