<template>
  <div class="calendar page-container">
    <calendar class="calendar-fixed-container" @dateChanged="update" @purge="purge" />
    <!--    <div class="background-mask"></div>-->
    <div v-show="isBusy" class="p-2 col-12 spinner-container">
      <b-spinner type="grow" label="Ładowanie..." class="spinner"></b-spinner>
    </div>
    <div class="day-picker-container" :class="{ 'animated fadeIn': !isBusy }">
      <div
        @click="select(date)"
        v-bind:key="date.id"
        v-for="date in dates"
        class="panel day-picker"
      >
        <div class="row">
          <div class="col-2 time">
            <div :class="{ align: date.time.split(':')[0].toString().length === 1 }">
              {{ date.time }}
            </div>
          </div>
          <div class="col-10">
            <div class="row">
              <div class="clinic col-12">{{ date.clinic }}</div>
              <div class="doctor col-12">{{ date.title }} {{ date.doctor_fullname }}</div>
              <div class="price col-12">{{ getPrice(date) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '../../components/Calendar.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Date',
  async created() {
    const vm = this
    vm.$http.post('e-zacma/statistics/register-visit-search')
    await this.cacheSettings()
  },
  data() {
    return {
      isBusy: false,
      dates: [],
      settings: []
    }
  },
  methods: {
    getPrice(data) {
      if (data.nfz_available) {
        return 'NFZ'
      }

      if (data.price === 0) {
        return 'Bezpłatna'
      }

      return `Cena: ${(data.price / 100).toFixed(2)} zł`
    },
    purge() {
      this.dates = []
    },
    async select(date) {
      const vm = this
      vm.$busy(true)
      await vm.$db.getItem('visit_type').then((visitType) => {
        date.visit_group_type = visitType
      })
      vm.$http
        .post('e-zacma/create-temporary-record', {
          ...date,
          salon_id: date.IDSalon,
          reason_id: date.visit_type
        })
        .then(
          async ({ data: { slot_reservation_id } }) => {
            await vm.$db.setItem('temporary_visit_info', { ...date, id: slot_reservation_id })
            await vm.$router.push({
              name: 'consent',
              params: { registration_id: slot_reservation_id }
            })
            vm.$busy(false)
          },
          (error) => {
            vm.$busy(false)
          }
        )
    },
    async cacheSettings() {
      const vm = this
      vm.settings = await vm.$db.getItem('calendar_request_settings')
      vm.clinics = await vm.$db.getItem('locations')
      if (process.env.NODE_ENV === 'production') {
        // vm.$sentry.captureMessage(`Settings ${JSON.stringify(vm.settings)}`)
      }
    },
    async update(date) {
      const vm = this
      vm.isBusy = true
      vm.dates = []
      const availableVisits = await vm.$db.getItem('availableVisits')
      const dateVisits = availableVisits[date.date]
      vm.dates = vm.refactor(dateVisits)
      vm.isBusy = false
    },
    getClinic(apiKey) {
      const clinic = this.clinics.find((cl) => cl.apiKey === apiKey)
      return `${clinic.address}, ${clinic.postalCode} ${clinic.city}`
    },
    refactor(dates) {
      dates.sort((a, b) => {
        a = a.time.split(':')
        b = b.time.split(':')
        if (parseInt(a[0], 10) > parseInt(b[0], 10)) {
          return 1
        }
        if (parseInt(a[0], 10) < parseInt(b[0], 10)) {
          return -1
        }
        if (parseInt(a[1], 10) > parseInt(b[1], 10)) {
          return 1
        }
        return -1
      })
      return dates.map((date) => ({ ...date, clinic: this.getClinic(date.api_key) }))
    }
  },
  components: {
    Calendar
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/presets';

.spinner-container {
  margin-top: 180px;

  .spinner {
    display: block;
    margin: 0 auto;
  }
}

.day-picker-container {
  animation-duration: 0.2s;
  // margin-top: 195px;
  z-index: -1;

  overflow-y: auto;
  margin: 0 -8px;
  padding: 0 8px;
  padding-bottom: 20px;
  height: calc(100vh - (52px + var(--safe-area-inset-top) * 0.65) - 205px - 0.6rem - 37px);

  > div:first-child {
    margin-top: 10px;
  }
}

.calendar-fixed-container {
  // position: fixed;
  max-width: calc(100vw - 18px);
  z-index: 1000;
}

.background-mask {
  position: fixed;
  height: 248px;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 900;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: repeating-linear-gradient(to bottom, lighten($blue-1, 7), $blue-1);
}

.time .align {
  padding-left: 10px;
}
</style>
