import 'alertifyjs/build/css/alertify.min.css'
import 'alertifyjs/build/css/themes/default.min.css'
import alertify from 'alertifyjs'

const Modal = {
  install(Vue, options = {}) {
    Vue.prototype.$modalConfirm = function (message, ok, cancel) {
      alertify
        .confirm(message, ok, cancel)
        .set('labels', { ok: 'Tak', cancel: 'Nie' })
        .set('closable', false)
        .set('defaultFocus', 'cancel')
        .set('frameless', false)
        .setHeader('Uwaga!')
    }

    Vue.prototype.$modalPrompt = function (title, message, ok, cancel) {
      alertify
        .prompt(title, message, '', (evt, value) => { ok(value) }, cancel)
        .set('labels', { ok: 'Zatwierdź', cancel: 'Anuluj' })
        .set('closable', false)
        .set('defaultFocus', 'cancel')
        .set('frameless', false)
    }
  }
}

export default Modal.install
