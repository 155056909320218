import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistance from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistance({
  storage: window.localStorage,
  reducer: (state) => ({ navigation: state.navigation }),
  supportCircular: true
})

export default new Vuex.Store({
  state: {
    pageLoader: {
      events: []
    },
    navigation: {
      currentViewName: '',
      lastViewChange: 0
    },
    calendarNeedsUpdate: {
      updateNeeded: false,
      refreshNow: false
    }
  },
  getters: {
    pageLoader: (state) => state.pageLoader.events.length > 0,
    navigation: (state) => state.navigation
  },
  mutations: {
    addPageLoaderEvent(state, event) {
      state.pageLoader.events.push(event)
    },
    removePageLoaderEvent(state, event) {
      state.pageLoader.events = state.pageLoader.events.filter((value) => value !== event)
    },
    viewChanged(state, viewName) {
      state.navigation.currentViewName = viewName
      state.navigation.lastViewChange = new Date().getTime()
    },
    calendarNeedToBeRerender(state, value) {
      state.calendarNeedsUpdate = value
    }
  },
  actions: {},
  plugins: [vuexLocal.plugin]
})
