import { render, staticRenderFns } from "./SMSLogin.vue?vue&type=template&id=35c49876&scoped=true"
import script from "./SMSLogin.vue?vue&type=script&lang=js"
export * from "./SMSLogin.vue?vue&type=script&lang=js"
import style0 from "./SMSLogin.vue?vue&type=style&index=0&id=35c49876&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35c49876",
  null
  
)

export default component.exports