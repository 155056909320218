import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import axios from 'axios'
import vueMoment from 'vue-moment'
import moment from 'moment'
import localforage from 'localforage'
import VueMask from 'v-mask'
import 'moment/locale/pl'
// eslint-disable-next-line import/extensions,import/no-unresolved
import { library } from '@fortawesome/fontawesome-svg-core'
// eslint-disable-next-line import/extensions, object-curly-newline
import {
  faArrowLeft,
  faBars,
  faChevronLeft,
  faChevronRight,
  faEnvelope,
  faMobile,
  faMoneyCheck,
  faTimes,
  faUser,
  faTimesCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHome,
  faFileImage,
  faFilePdf,
  faPlus,
  faFileAlt,
  faPencilAlt,
  faUndo,
  faSearchPlus,
  faSearchMinus,
  faSync,
  faTablet,
  faLock
} from '@fortawesome/free-solid-svg-icons'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'animate.css'
import iziToast from 'izitoast'
import VueZoomer from 'vue-zoomer'
import App from './App.vue'
// import './plugins/application-freeze-handler'
import BusyIndicator from './plugins/busy-indicator'
import Modal from './plugins/modal'
import router from './router'
import loader from './plugins/loader'
import store from './store'
import auth from './plugins/auth'
import errorHandler from './plugins/error-handler'
import offlineIndicator from './plugins/offline-indicator'
import './assets/js/modernizr'
import './registerServiceWorker'
import 'core-js'
import { jwtDecode } from 'jwt-decode'

const firebaseConfig = {
  apiKey: 'AIzaSyCKKJ_Rb13NzPFnkAcCC4pDubMY99_LYVQ',
  authDomain: 'e-unison.firebaseapp.com',
  projectId: 'e-unison',
  storageBucket: 'e-unison.appspot.com',
  messagingSenderId: '491587364218',
  appId: '1:491587364218:web:03cefd21cd1a868eb722c3',
  measurementId: 'G-3428LC1N2C'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

const logEventCustom = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams)
}

library.add(
  faMobile,
  faEnvelope,
  faMoneyCheck,
  faUser,
  faChevronLeft,
  faChevronRight,
  faArrowLeft,
  faBars,
  faTimes,
  faTimesCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faHome,
  faFileImage,
  faFilePdf,
  faPlus,
  faFileAlt,
  faPencilAlt,
  faUndo,
  faSearchPlus,
  faSearchMinus,
  faSync,
  faLock,
  faTablet
)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://300bdc2b82d94b919a1cec7d76ad517b@sentry.io/1475726',
//     integrations: [
//       new Integrations.Vue({
//         Vue,
//         attachProps: true
//       })
//     ]
//   })
//   Vue.prototype.$sentry = Sentry
// } else {
//   Vue.prototype.$sentry = window.console
// }

Vue.use(BusyIndicator)

Vue.use(Modal)

Vue.use(loader)

Vue.use(VueZoomer)

Vue.use(BootstrapVue)
Vue.use(vueMoment, {
  moment
})
Vue.moment().locale()

Vue.config.productionTip = false

Vue.prototype.$http = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_API_URL}/api`
})

Vue.prototype.$axios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})
delete Vue.prototype.$axios.defaults.headers.common.Authorization

Vue.prototype.$logEvent = logEventCustom

Vue.prototype.$db = localforage.createInstance({
  name: 'unison'
})

Vue.prototype.$toast = iziToast

Vue.use(auth, {
  prefix: '/auth'
})

Vue.use(VueMask)

Vue.use(offlineIndicator)

Vue.use(errorHandler)

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
